import { Component, OnInit } from '@angular/core';
import { TypeKey } from '@wissenswerft/core/data';

@Component({
  selector: 'damage-kind',
  templateUrl: './damage-kind.component.html',
  styleUrls: ['./damage-kind.component.scss']
})
export class DamageKindComponent implements OnInit {
  public typeKey: TypeKey = TypeKey.damageKind;

  constructor() { }

  ngOnInit(): void {

  }

}
