import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ConfigService } from './config.service';

@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers: [ConfigService, HttpClient]
})
export class CoreConfigurationModule {}
