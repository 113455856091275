import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PersistenceService, TypeKey } from '@wissenswerft/core/data';
import { Column } from 'devextreme/ui/data_grid';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Measure } from '../../models/measure.model';
import { DataDefinitionViewModel } from '../../view-models/customer-space.view-model';
import { MeasureViewModel, VISIBLE_PROPERTIES } from '../../view-models/measure.view-model';
import { DxPopupComponent } from 'devextreme-angular/ui/popup';
import { DataService } from '../../../shared/data.service';
import { Router } from '@angular/router';
import { GridComponent } from '../../shared/ww-grid/grid.component';
import { MeasureService } from './measure.service';
import { ToastType } from '../../shared/ww-toast/toast.model';

@Component({
  selector: 'cmt-measure-list',
  templateUrl: './measure-list.component.html',
  styleUrls: ['./measure-list.component.scss']
})
export class MeasureListComponent implements OnInit, OnDestroy {

  @ViewChild(' mesureDetailsPopup') mesureDetailsPopup: DxPopupComponent;
  @ViewChild('confirmPopup') confirmPopup: DxPopupComponent;
  @ViewChild('measureGrid') measureGrid: GridComponent;


  private destroy$ = new Subject<void>();
  private measureId;

  public measures: MeasureViewModel[];
  public columnsHeader: Column[] = [];
  public title: string;
  public detailsMode: string;
  private groupByColumnIndex = [];
  public propertiesDefinition;
  constructor(private dataService: DataService, private router: Router, private measureService: MeasureService,
    private persistenceService: PersistenceService) { }

  ngOnInit(): void {
    this.dataService.checkGridGroupByColumns('measureGrid', this.groupByColumnIndex);
    if (this.dataService.definitionsVM[TypeKey.measure]) {
      this.title = this.dataService.definitionsVM[TypeKey.measure].namePlural;
    }
    this.dataService.getDefinitionAndData<Measure[]>(TypeKey.measure)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        const definitions = data[0];
        this.dataService.definitionsVM[TypeKey.measure] = definitions;
        this.title = definitions.namePlural;
        const measures = data[1];
        this.measures = [];
        const measureDefinitionVM = new DataDefinitionViewModel(definitions, VISIBLE_PROPERTIES);
        this.dataService.definitionsVM[TypeKey.measure].measureDefinitionVM = measureDefinitionVM;
        const properties = measureDefinitionVM.properties;
        this.propertiesDefinition = properties;
        for (const key in properties) {
          if (properties[key]) {
            const property = properties[key];
            this.columnsHeader.push({
              dataField: property.key,
              caption: property.label,
              visible: property?.visible,
              dataType: this.dataService.getDataType(property.type),
              groupIndex: (property.key === 'status') ? 0 : (this.groupByColumnIndex[property.key]) ? this.groupByColumnIndex[property.key] : undefined,
              lookup: (this.getLookupColumn(property.key)) ? {
                dataSource: this.getLookupColumn(property.key).dataSource,
                displayExpr: this.getLookupColumn(property.key).displayExpr,
                valueExpr: this.getLookupColumn(property.key).valueExpr
              } : undefined,
            });
          }
        }
        this.columnsHeader.push({
          type: "buttons",
          caption: '',
          alignment: 'left',
          minWidth: 70,
          dataField: 'edit',
          buttons: [{
            icon: 'edit',
            onClick: (e) => { this.openMeasureDetail(e); }
          }, {
            icon: "trash",
            onClick: (e) => { this.openConfirmDialog(e) }
          }]
        })
        measures.forEach((measure: Measure) => {
          const measureViewModel = new MeasureViewModel(measure);
          this.measures.push(measureViewModel);
        });
      });

    this.dataService.updateGridData$.subscribe((measure: MeasureViewModel) => {
      this.measures.push(measure);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private openMeasureDetail(event): void {
    const measureDetail = new Measure(event.row.data.measure || event.row.data);
    this.dataService.sendMeasureDetail(measureDetail);
    this.router.navigate(['measureDetail', measureDetail.id]);
  }

  private openConfirmDialog(event): void {
    this.measureId = event.row.data.measure.id || event.row.data.id;
    this.confirmPopup.instance.show();
  }

  public onClosePopup(): void {
    this.mesureDetailsPopup.instance.hide();
  }

  public openMeasureDialog(): void {
    this.mesureDetailsPopup.instance.show()
  }

  private getLookupColumn(key: string) {
    switch (key) {
      case 'orgaTech':
        return { valueExpr: 'id', displayExpr: 'label', dataSource: this.measureService.orgaTech };
    }
  }

  public removeRow(event): void {
    this.persistenceService.addObjectForDelete(this.measureId)
      .subscribe(() => {
        const index = this.measureGrid.gridData.findIndex(element => element.measure.id == this.measureId);
        this.measureGrid.gridData.splice(index, 1);
        this.dataService.appService.callNotification({ message: 'Object deleted', type: ToastType.INFO });
      }, error => {
        this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
      });
    this.confirmPopup.instance.hide();
  }

  public abortDelete(): void {
    this.confirmPopup.instance.hide();
  }

}
