import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Country, DamageKind, Module, RiskIssue, Standard, SubModule } from '@wissenswerft/cmt/catalog-library';
import { ProfileInfo } from '@wissenswerft/core/authentication';
import { ConvertHelper, CoreDataService, PersistenceService, TypeKey } from '@wissenswerft/core/data';
import { Column } from 'devextreme/ui/data_grid';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataDefinition, RiskAssesmentMeasure } from '../../../models/customer-space.model';
import { Measure } from '../../../models/measure.model';
import { RiskAssessment, AmountOfDamage, Status, PropertyEnum } from '../../../models/risk-assesment.model';
import { DxButtonComponent, DxPopupComponent } from 'devextreme-angular';
import { DataService } from '../../../../shared/data.service';
import { RiskAssessmentService } from '../risk-assessment.service';
import { VISIBLE_PROPERTIES } from '../../../view-models/measure.view-model';
import { DataDefinitionViewModel } from '../../../view-models/customer-space.view-model';
import { ActivatedRoute, Router } from '@angular/router';
import { GridComponent } from '../../../shared/ww-grid/grid.component';
import { ClickOrigin, Source } from '../../../models/home.model';
import { Comment } from 'libs/core/comments/src/lib/models/comment.model';
import { ToastType } from '../../../shared/ww-toast/toast.model';

@Component({
  selector: 'cmt-risk-assesment-detail',
  templateUrl: './risk-assesment-detail.component.html',
  styleUrls: ['./risk-assesment-detail.component.scss']
})

export class RiskAssesmentDetailComponent implements OnInit, OnDestroy {
  @ViewChild('statutButton', { static: false }) statutButton: DxButtonComponent;
  @ViewChild('assignMeasureButton', { static: false }) assignMeasureButton: DxButtonComponent;
  @ViewChild('createMeasurePopup') createMeasurePopup: DxPopupComponent;
  @ViewChild('confirmPopup') confirmPopup: DxPopupComponent;
  @ViewChild('massnahmenGrid') massnahmenGrid: GridComponent;

  @Input() detailsMode: string;
  public measures: Measure[];
  public risk: RiskAssessment;
  public measureSuggestions: Array<RiskAssesmentMeasure>;
  public measureSuggestionsColumns: Column[] = [
    {
      dataField: 'measure.title', caption: 'Measure'
    }
  ];
  public assignedMeasureColumns: Column[];
  // = [
  //   {
  //     dataField: 'measure.title', caption: 'Measure'
  //   },
  //   {
  //     dataField: 'value', alignment: 'left', dataType: 'number', caption: 'Risk Reduction', customizeText: this.addPercentSymbol,
  //     format: { type: 'fixedPoint', precision: 0 }
  //   }
  //   // ,
  //   // {
  //   //   type: "buttons",
  //   //   caption: '',
  //   //   alignment: 'left',
  //   //   minWidth: 70,
  //   //   dataField: 'edit',
  //   //   buttons: [{
  //   //     icon: 'edit',
  //   //     onClick: (e) => { this.openMeasureDetail(e.row.data.measure.id); }
  //   //   }, {
  //   //     icon: "trash",
  //   //     onClick: (e) => { this.openConfirmDialog(e.row.data.id); }
  //   //   }]
  //   // }
  // ];
  public assignedMeasure: Array<RiskAssesmentMeasure>;
  public module: Module;
  public standards: Observable<Standard[]>;
  public amountOfDamage = ConvertHelper.ToArray(AmountOfDamage);
  public damageKinds: Observable<DamageKind[]>;
  public countries: Observable<Country[]>;
  public riskProperties;
  public allComments: Comment;
  public riskAssesmentComments: Comment[];
  public currentScope: string;
  public selectedModule: Module;
  public subModules: Observable<SubModule[]>;
  private assignMeasure: string;
  private assignActivity: string;
  private selectedMeasure: Measure;
  private riskAssessmenMeasureId: number;
  

  constructor(private coreDataService: CoreDataService,
    private dataService: DataService,
    private router: Router,
    private persistenceService: PersistenceService,
    public riskService: RiskAssessmentService,
    private actRoute: ActivatedRoute) {
      if (!this.riskService.riskAssessmentDefinitionVM) {
        this.coreDataService.getDynamicContentItemById<RiskAssessment>(this.actRoute.snapshot.params.id).subscribe((risk => {
          const riskDetail = new RiskAssessment(risk);
          this.coreDataService.getDefinitonByTypeKey<DataDefinition>(TypeKey.riskAssessment).subscribe((def =>{
            const riskAssessmentDefinitionVM = new DataDefinitionViewModel(def, VISIBLE_PROPERTIES);
            this.riskService.riskAssessmentDefinitionVM = riskAssessmentDefinitionVM;
            this.dataService.sendRiskDetail({
              riskAssessment: riskDetail,
              riskDefinition: this.riskService.riskAssessmentDefinitionVM
            });
          }))
          
        }))
        
      }
      
      this.dataService.getRiskDetail().subscribe(data => {
        if (data) {
          this.risk = data.riskAssessment;
          if (!this.risk.status) this.risk.status = Status.open;
          if (this.risk.probability) this.risk.probability = this.convertToNumber(this.risk.probability);
          if (this.risk.amountOfDamage) this.risk.amountOfDamage = this.convertToNumber(this.risk.amountOfDamage);
          this.riskProperties = data.riskDefinition.properties;
          this.getModule(this.risk.subModule.module);
        }
      });
      // this.coreDataService.getAllComments<Comment>().subscribe(data => {
      //   this.allComments = data;
      // });
      
    
  }

  ngOnInit(): void {
    
    this.measureSuggestions = [];
    this.assignedMeasure = [];
    this.currentScope = this.coreDataService.getScope();
    this.riskService.getSelectedModule().subscribe(module => {
      // this.selectedModule = module;
      // this.onSelectModule({id: this.selectedModule.id});
    });
    this.standards = this.coreDataService.getItemsByName(TypeKey.standard);
    this.damageKinds = this.coreDataService.getItemsByName(TypeKey.damageKind);
    this.countries = this.coreDataService.getItemsByName(TypeKey.country);
    // this.coreDataService.getItemsByName(TypeKey.measure).subscribe((data: Measure[]) => {
      
    // });
    // if (!this.dataService.definitionsVM[TypeKey.measure]) {
    this.dataService.getDefinitionAndData<Measure[]>(TypeKey.measure)
      .subscribe((data) => {
        const definitions = data[0];
        this.measures = data[1];
        this.dataService.definitionsVM[TypeKey.measure] = definitions;
        const measureDefinitionVM = new DataDefinitionViewModel(definitions, VISIBLE_PROPERTIES);
        this.dataService.definitionsVM[TypeKey.measure].measureDefinitionVM = measureDefinitionVM;
        this.assignedMeasureColumns = [
          {
            dataField: 'measure.id', caption: 'Measure', lookup: {
              dataSource: this.measures,
              displayExpr: 'title',
              valueExpr: 'id'
            }
          },
          {
            dataField: 'value', alignment: 'left', dataType: 'number', caption: 'Riskoreduktion', customizeText: this.addPercentSymbol,
            format: { type: 'fixedPoint', precision: 0 }
          }
        ];
        
      });
    // }
    this.dataService.getRiskAssessmentMeasures()
      .pipe(map((data) => {
        this.assignedMeasure = [];
        const riskAssessmentMeasures: RiskAssesmentMeasure[] = data[0] as RiskAssesmentMeasure[];
        riskAssessmentMeasures.forEach((riskAssessmentMeasure => {
          const ram = new RiskAssesmentMeasure(riskAssessmentMeasure);
          if (ram.type === 'suggestion') {
            this.measureSuggestions.push(ram);
          }
          else {
            if (ram.riskIssue && ram.riskIssue?.id === this.risk?.id) {
              this.assignedMeasure.push(ram);
            }
          }
        }));
        return riskAssessmentMeasures;
      })).subscribe();
    this.dataService.updateGridData$.subscribe((data) => {
      this.measures.push(data);
    });
    if (this.risk) {
      this.coreDataService.getCommentsByItemId<Comment[]>(this.risk.id).subscribe(data => {
        this.riskAssesmentComments = data;
      });
    }
  }

  public onCustomItemCreating(event) {
    const queries = [];
    this.dataService.getRiskAssessmentMeasures()
      .subscribe((data) => {
        const riskAssessmentMeasures: RiskAssesmentMeasure[] = data[0] as RiskAssesmentMeasure[];
        riskAssessmentMeasures.forEach((riskAssessmentMeasure => {
          const ram = new RiskAssesmentMeasure(riskAssessmentMeasure);
          if (ram.type === 'suggestion') {
            this.measureSuggestions.push(ram);
          } else {
            if (ram.riskIssue.id === this.risk.id) {
              this.assignedMeasure.push(ram);
            }
          }
        }));
      });
  }

  public getTagBoxLabel(e) {
    return e?.label || e?.ident;
  }

  public convertToNumber(value): number {
    return parseInt(value);
  }

  public setProbabilityAverage(amountOfDamage: number, probability: number): number {
    const gaugeValue = ((amountOfDamage * probability) * 100) / 49;
    return gaugeValue;
  }

  public ratingChanged(event, kind): void {
    const selectBox = document.getElementById(event.element.id);
    this.risk[kind] = event.selectedItem.value;
    switch (event.selectedItem.value) {
      case 7:
        selectBox.style.backgroundColor = "red";
        break;
      case 5:
        selectBox.style.backgroundColor = "orange";
        break;
      case 3:
        selectBox.style.backgroundColor = "yellow";
        break;
      case 1:
        selectBox.style.backgroundColor = "lime";
        break;
    }
  }

  public getResponsibleFullName(responsible: ProfileInfo) {
    if (responsible) {
      return responsible.lastname + ', ' + responsible.firstname;
    }
  }

  customizeText(arg: any) {
    return arg.valueText + " %";
  }

  public persistChanges(event) {
    const multilingualProperties = this.riskService.riskAssessmentDefinitionVM.multilingualProperties;
    delete this.risk.meta.updated;
    const query: any = Object.assign({}, this.risk);
    multilingualProperties.forEach(prop => {
      query[prop.key] = { "de": query[prop.key], 'en': query[prop.key] };
    });
    this.persistenceService.addObjectForUpdate(this.risk.id, query).subscribe(() => {
      this.dataService.appService.callNotification({ message: 'Erfolg', type: ToastType.SUCCESS });
    });
  }

  public assignMeasureToRisk(): void {
    if (this.selectedMeasure) {
      const query: RiskAssesmentMeasure = new RiskAssesmentMeasure({
        measure: this.selectedMeasure,
        riskIssue: this.risk,
        type: 'assigned',
        value: 0
      });
      this.persistenceService.addObjectForInsert(TypeKey.riskAssessmentMeasure, query).subscribe((data) => {
        query.id = data.id;
        this.massnahmenGrid.gridData.push(query);
        this.dataService.appService.callNotification({ message: 'Erfolg', type: ToastType.SUCCESS });
      }, error => {
        this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
      });
    } else {
      this.createMeasurePopup.instance.show();
    }
  }

  ngOnDestroy(): void {
  }

  public openConfirmDialog(id): void {
    this.riskAssessmenMeasureId = id;
    this.confirmPopup.instance.show();
  }

  public onMeasureSelectionChanged(e) {
    this.selectedMeasure = e.selectedItem;
  }

  public changeButtonStatut(): void {
    if (this.statutButton.text === Status.bewertet) { this.statutButton.text = Status.open; this.risk.status = Status.open; }
    else if (this.statutButton.text === Status.open) { this.statutButton.text = Status.planned; this.risk.status = Status.planned; }
    else if (this.statutButton.text === Status.planned) { this.statutButton.text = Status.bewertet; this.risk.status = Status.bewertet; }
  }

  public onResponsibleChange(e) {
    this.risk.responsible = new ProfileInfo(this.riskService.responsibleById[e.value]);
  }

  public onScopeChange(e) {
    this.risk.subModule = new SubModule(this.riskService.scopeById[e.value]);
  }

  public setResponsible(e) {
    return e ? e.id : null;
  }

  public setScope(e) {
    return e ? e.id : null;
  }

  public getUpdatedDateTime() {
    if (this.risk.meta.updated) {
      const updated = new Date(this.risk.meta.updated);
      return updated.toLocaleDateString() + ' ' + updated.toLocaleTimeString();
    }
  }

  public onClosePopup(): void {
    this.createMeasurePopup.instance.hide();
  }

  private openMeasureDetail(measureId: string): void {
    const riskSource: ClickOrigin = new ClickOrigin({ id: this.risk.id, source: Source.RiskDetail, value: true });
    this.dataService.setClickOrigin(riskSource);
    this.router.navigate(['measureDetail', measureId]);
  }

  public abortDelete() {
    this.confirmPopup.instance.hide();
  }

  public removeRow(): void {
    this.persistenceService.addObjectForDelete(this.riskAssessmenMeasureId).subscribe(() => {
      this.dataService.appService.callNotification({ message: 'Objekt gelöscht', type: ToastType.INFO });
    }, error => {
      this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
    });
    this.confirmPopup.instance.hide();
    this.massnahmenGrid.refreshGrid();
  }

  private addPercentSymbol(arg): string {
    return arg.valueText + '%';
  }

  public backToRiskList(): void {
    this.router.navigateByUrl('risks');
  }

  public getDisplayExpr(obj: SubModule | Module) {
    if (obj) {
      if (obj?.label) {
        return obj.label;
      } else {
        return obj.ident;
      }
    }
  }

  public getValueExpression(object: SubModule | Module) {
    if (object) {
      return object.id;
    }
  }

  public onSelectModule(event) {
    const id = event?.itemData?.id || event.id;
    this.subModules = this.coreDataService.getItemsBySpoqlQuery('subModule', `property 'module' eq ${id}`);
    return this.subModules;
  }

  private getModule(moduleId) {
    this.coreDataService.getDynamicContentItemById<Module>(moduleId).subscribe((module => {
      this.selectedModule = new Module(module);
      this.onSelectModule(this.selectedModule)
    }))
  }

  public updateRow(event): void {
    console.log(event)
    const query = this.dataService.prepareRiskAssessmentUpdate(event.data);
    this.persistenceService.addObjectForUpdate(event.data.id, query).subscribe(() => {
      this.dataService.appService.callNotification({ message: 'Erfolg', type: ToastType.SUCCESS });
    }, error => {
      this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
    });
  }

  public onRiskDetailsValueChanged(key, event) {
    if (this.risk[key]) {
      this.risk[key] = event.value;
    }
  }

}