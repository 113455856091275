import { Component, OnInit } from '@angular/core';
import { TypeKey } from '@wissenswerft/core/data';

@Component({
  selector: 'measure-type',
  templateUrl: './measure-type.component.html',
  styleUrls: ['./measure-type.component.scss']
})
export class MeasureTypeComponent implements OnInit {
  public typeKey: TypeKey = TypeKey.measureType;

  constructor() { }

  ngOnInit(): void {

  }

}
