import { Component, OnInit } from '@angular/core';
import { TypeKey } from '@wissenswerft/core/data';

@Component({
  selector: 'measure-definition',
  templateUrl: './measure-definition.component.html',
  styleUrls: ['./measure-definition.component.scss']
})
export class MeasureDefinitionComponent implements OnInit {
  public typeKey: TypeKey = TypeKey.measureDefinition;

  constructor() { }

  ngOnInit(): void {

  }

}