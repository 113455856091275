import { NamedItem, Tag, MeasureBenefit } from "./catalog-library.model";

export class MeasureRecommendation extends NamedItem {
    goal: string; // RichText
    tags: Tag[];
    benefits: MeasureBenefit[] | number[];

    constructor(data) {
        super();
        Object.assign(this, data);
    }
}