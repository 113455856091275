export class LoginInfo {
  constructor(
    public readonly accessToken: string,
    public readonly expiresIn: number,
    public readonly refreshToken: string,
    public readonly tokenType: string,
    public readonly expiryDate?: Date
  ) {
    const now = new Date();
    this.expiryDate = new Date(now.getTime() + this.expiresIn * 1000);
  }
}