import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { DxFormModule, DxLoadIndicatorModule, DxScrollViewModule, DxButtonModule } from "devextreme-angular";
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthenticationGuard } from './services/authentication.guard';
import { UserService } from './services/user.service';
import { TokenService } from './services/token.service';
import { Interceptor } from './interceptor/interceptor';
import { IdeTimerService } from './services/idleTimer.service';
import { CoreConfigService } from '@wissenswerft/core/configuration'

@NgModule({
  imports: [
    CommonModule,
    DxFormModule,
    DxLoadIndicatorModule,
    DxScrollViewModule,
    DxButtonModule
  ],
  declarations: [LoginComponent],
  providers: [
    HttpClient,
    AuthenticationGuard,
    UserService,
    TokenService,
    IdeTimerService,
    CoreConfigService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      deps: [TokenService, CoreConfigService],
      multi: true
    }],
    exports: [LoginComponent]
})
export class CoreAuthenticationModule { }
