import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, range, throwError } from 'rxjs';
import { TokenService } from './token.service';
import { catchError, concatMap, first, map, take, tap } from 'rxjs/operators';
import { ConfigService } from '../../../../configuration/src/lib/config.service';
import { LoginInfo } from '../models/login-info.model';
import { ProfileInfo } from '../models/profile-info.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient, private tokenService: TokenService, private configService: ConfigService) { }
  private loginInfo: LoginInfo;
  private profileInfo: ProfileInfo;

  private static handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError(
      'Something bad happened; please try again later.');
  }

  login(loginData: any): Observable<any> {
    this.tokenService.removeToken();
    this.tokenService.removeRefreshToken();
    const body = new HttpParams()
      .set('client_id', this.configService.configuration.OAuth2.ClientId)
      .set('username', loginData.email)
      .set('password', loginData.password)
      .set('grant_type', 'password');

    return this.http.post<any>(this.configService.configuration.WebApi.ServiceURLs.Account_Generate_Token, body)
      .pipe(
        tap((res) => {
          this.loginInfo = new LoginInfo(res.access_token, res.expires_in, res.refresh_token, res.token_type);
          this.tokenService.saveToken(this.loginInfo);

        }),
        concatMap(() => this.getProfileInformations()),
        catchError(UserService.handleError)
      );
  }

  getProfileInformations(): Observable<ProfileInfo> {
    if (!this.profileInfo) {
      return this.http.get<ProfileInfo>(this.configService.configuration.WebApi.ServiceURLs.Account_Profile).pipe(tap((profile) => {
        this.profileInfo = new ProfileInfo(profile);
      }));
    } else {
      return of(this.profileInfo);
    }
  }

  public isAuthenticated(): boolean {
    if (this.tokenService.getToken() && !this.isExpired()) {
      return true;
    }
    return false;
  }

  public isExpired(): Boolean {
    let isExpired: Boolean;
    isExpired = new Date() > new Date(this.tokenService.getExpiryDate()) ? true : false;
    return isExpired;
  }

  refreshToken(): Observable<any> {
    const body = new HttpParams()
      .set('refresh_token', localStorage.getItem("refresh_token"))
      .set('grant_type', 'refresh_token');
    return this.http.post<any>(this.configService.configuration.WebApi.ServiceURLs.Account_Refresh_Token, body).pipe();
  }

  clearLoginInfo() {
    this.tokenService.clearLoginInfo();
  }

}