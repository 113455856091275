import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProfileInfo, UserService } from '@wissenswerft/core/authentication';
import { CoreConfigService } from '@wissenswerft/core/configuration';
import { CoreDataService } from '@wissenswerft/core/data';
import { AccessibleScopes } from 'libs/core/authentication/src/lib/models/profile-info.model';
import { AppService } from '../../app.service';
import { DataService } from '../../shared/data.service';
import { HomeMode, HomeOptions } from '../models/home.model';

@Component({
  selector: 'cmt-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public profileInformation: ProfileInfo = new ProfileInfo(null);
  public homeOptions = HomeOptions;
  public companyChoices: AccessibleScopes[] = [];

  constructor(private router: Router, public dataService: DataService, public appService: AppService,
    private coreDataService: CoreDataService,
    private userService: UserService,
    private coreConfigservice: CoreConfigService) { }

  ngOnInit(): void {
    this.userService.getProfileInformations().subscribe(profileInfo => {
      this.profileInformation = new ProfileInfo(profileInfo);
      this.companyChoices = this.profileInformation.accessibleScopes;
      this.companyChoices.forEach(choice => {
        choice.tileIconUrl = this.coreConfigservice.configuration.WebApi.BaseURL.concat(choice.tileIconUrl);
      });
    });
  }

  public itemClick(item): void {
    localStorage.setItem('scopeKey', item.text);
    this.coreDataService.prepareTitleScope(item.html);
    // if (this.appService.getHomeMode() === HomeMode.home) this.router.navigateByUrl(item.text);
    // else {
    //   this.coreDataService.setScope(item.text);
    //   this.appService.setHomeMode(HomeMode.home);
    // }
    this.coreDataService.setScope(item.text);
    this.router.navigateByUrl('dashboard')
  }

}
