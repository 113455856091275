import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { DataService } from 'apps/cmt/src/app/shared/data.service';
import { Measure, MeasureDefinition, MeasureTask, OrgaTech, PrevDect, Status } from '../../../models/measure.model';
import { ConvertHelper, CoreDataService, PersistenceService, TypeKey } from '@wissenswerft/core/data';
import { Observable } from 'rxjs';
import { MeasureBenefit } from '@wissenswerft/cmt/catalog-library';
import { RiskAssesmentMeasure } from '../../../models/customer-space.model';
import { map } from 'rxjs/operators';
import { RiskAssessment } from '../../../models/risk-assesment.model';
import { Column } from 'devextreme/ui/data_grid';
import { ActivatedRoute, Router } from '@angular/router';
import DataSource from 'devextreme/data/data_source';
import { DataDefinitionViewModel } from '../../../view-models/customer-space.view-model';
import { VISIBLE_PROPERTIES, MEASURE_TASK_VISIBLE_PROPERTIES } from '../../../view-models/measure.view-model';
import { MeasureService } from '../measure.service';
import { GridComponent } from '../../../shared/ww-grid/grid.component';
import { ProfileInfo } from '@wissenswerft/core/authentication';
import { RiskAssessmentService } from '../../risk-assesment/risk-assessment.service';
import { DxPopupComponent } from 'devextreme-angular';
import { Source } from '../../../models/home.model';
import { Comment } from 'libs/core/comments/src/lib/models/comment.model';
import { ToastType } from '../../../shared/ww-toast/toast.model';

@Component({
  selector: 'cmt-measure-detail',
  templateUrl: './measure-detail.component.html',
  styleUrls: ['./measure-detail.component.scss']
})
export class MeasureDetailComponent implements OnInit, OnDestroy {
  @Input() createMeasure = false;
  @ViewChild('measureTasksGrid', { static: false }) measureTasksGrid: GridComponent;
  @ViewChild('createRiskPopup') createRiskPopup: DxPopupComponent;

  private measureId;
  public currentScope: string;
  public measure: Measure;
  public allComments: Comment;
  public measureComments: Comment;
  public measureBenefits: Observable<MeasureBenefit[]>;
  public measureTasks: MeasureTask[] = [];
  public measureDefinitions: Observable<MeasureDefinition[]>;
  public assignedRisks: RiskAssesmentMeasure[] = [];
  private measureTasksDefinition: DataDefinitionViewModel;
  public allRisks: RiskAssessment[] = [];
  private newRisk;
  private addedRisk;
  private selectedRisk: RiskAssessment;
  public assignedRisksColumns: Column[];

  public tasksColumns: Column[] = [];
  public orgaTech = ConvertHelper.ToArray(OrgaTech);
  public prevDect = ConvertHelper.ToArray(PrevDect);
  public responsibles: Observable<ProfileInfo[]>;


  constructor(
    private coreDataService: CoreDataService, private persistenceService: PersistenceService,
    private dataService: DataService, private activatedRoute: ActivatedRoute, private router: Router,
    private measureService: MeasureService, private riskService: RiskAssessmentService) {
    this.measureId = this.activatedRoute.snapshot.params.id;
    this.coreDataService.getAllComments<Comment>().subscribe(data => {
      this.allComments = data;
    });
  }

  ngOnInit(): void {
    this.responsibles = this.coreDataService.getAllAccounts<ProfileInfo[]>();
    this.dataService.getDefinitionAndData<MeasureTask[]>(TypeKey.measureTask).subscribe((data) => {
      const definitions = data[0];
      const measureTasks = data[1];
      const measureDefinitionVM = new DataDefinitionViewModel(definitions, MEASURE_TASK_VISIBLE_PROPERTIES);
      this.measureTasksDefinition = measureDefinitionVM;
      const properties = measureDefinitionVM.properties;
      for (const key in properties) {
        if (properties[key]) {
          const property = properties[key];
          this.tasksColumns.push({
            dataField: property.key,
            caption: property.label,
            visible: property?.visible,
            dataType: this.dataService.getDataType(property.type),
            groupIndex: (property.key === 'subModule') ? 0 : undefined,
            lookup: (this.getLookupColumn(property.key)) ? {
              dataSource: this.getLookupColumn(property.key).dataSource,
              displayExpr: this.getLookupColumn(property.key).displayExpr,
              valueExpr: this.getLookupColumn(property.key).valueExpr
            } : undefined
          });
        }
      }
      measureTasks.forEach((measureTask => {
        if (measureTask?.measure?.toString() === this.measureId) {
          this.measureTasks.push(measureTask);
        }
      }));
    });

    this.dataService.getDefinitionAndData<RiskAssessment[]>(TypeKey.riskAssessment)
      .subscribe((data) => {
        const definitions = data[0];
        const riskAssessments = data[1];
        const riskAssessmentDefinitionVM = new DataDefinitionViewModel(definitions, VISIBLE_PROPERTIES);
        this.riskService.riskAssessmentDefinitionVM = riskAssessmentDefinitionVM;
        this.allRisks = riskAssessments;
        this.assignedRisksColumns = [
          {
            dataField: 'riskIssue.id', caption: 'Risiko', lookup: {
              dataSource: riskAssessments,
              displayExpr: 'title',
              valueExpr: 'id'
            }
          },
          {
            dataField: 'value', caption: 'Riskoreduktion'
          }
        ];
      });

    this.measureBenefits = this.coreDataService.getItemsByName(TypeKey.measureBenefit);
    this.measureDefinitions = this.coreDataService.getItemsByName(TypeKey.measureDefinition);
    this.dataService.getRiskAssessmentMeasures()
      .pipe(map((data) => {
        this.assignedRisks = [];
        const riskAssessmentMeasures: RiskAssesmentMeasure[] = data[0] as RiskAssesmentMeasure[];
        riskAssessmentMeasures.forEach((riskAssessmentMeasure => {
          const ram = new RiskAssesmentMeasure(riskAssessmentMeasure);
          if (ram?.measure?.id === this.measure.id && ram.type === 'assigned') {
            this.assignedRisks.push(ram);
          }
        }));
        return riskAssessmentMeasures;
      })).subscribe();
    this.dataService.getMeasureDetail().subscribe(measureDetail => {
      this.measure = measureDetail;
    });
    this.coreDataService.getCommentsByItemId<Comment>(this.measureId).subscribe(data => {
      this.measureComments = data;
    });
    if (!this.measure?.id) {
      this.coreDataService.getDynamicContentItemById<Measure>(this.measureId).subscribe((measure) => {
        this.measure = measure;
      });
    }
  }


  public persistChanges(event) {
    const multilingualProperties = this.dataService.definitionsVM[TypeKey.measure].measureDefinitionVM.multilingualProperties;
    delete this.measure.meta.updated;
    const query: any = Object.assign({}, this.measure);
    multilingualProperties.forEach(prop => {
      query[prop.key] = { "de": query[prop.key], 'en': query[prop.key] };
    });
    this.persistenceService.addObjectForUpdate(this.measure.id, query).subscribe(() => {
      this.dataService.appService.callNotification({ message: 'Erfolg', type: ToastType.SUCCESS });
    }, error => {
      this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
    });
  }

  private getLookupColumn(key: string) {
    switch (key) {
      case 'degreeOfImplementation':
      case 'efficiency':
        return { valueExpr: 'id', displayExpr: 'value', dataSource: this.measureService.degree };
    }
  }

  public addRow(): void {
    this.measureTasksGrid.addRow();
  }

  getLastUpdatedDateTime() {
    const updated = new Date(this.measure.meta.updated);
    return updated.toLocaleDateString() + ' ' + updated.toLocaleTimeString();
  }

  customizeText(arg: any) {
    return arg.valueText + " %";
  }

  average(target) {
    let sum = 0;
    let tasks = 0;
    this.measureTasks?.forEach((task) => {
      sum += task[target];
      tasks++;
    });
    const average = sum / tasks || 0;
    if (average > 0 && average < 100) {
      this.measure.status = Status.inprogress;
    } else if (average === 100) {
      this.measure.status = Status.done;
    } else {
      this.measure.status = Status.open;
    };
    return average;
  }

  public addTask(): void {
    this.measureTasksGrid.addEmptyRow();
  }

  ngOnDestroy(): void {
  }

  public addRiksAllocation(event): void {
    let index = this.allRisks.findIndex((element) => element.title === this.selectedRisk?.title);
    if (this.isAssigned() == false) {
      if (index !== -1) {
        const query: RiskAssesmentMeasure = new RiskAssesmentMeasure({
          measure: this.measure,
          riskIssue: this.allRisks[index],
          type: 'assigned',
          value: 0
        });
        this.persistenceService.addObjectForInsert(TypeKey.riskAssessmentMeasure, query).subscribe((data) => {
          this.assignedRisks.push(query);
        });
      }
      else {
        this.createRiskPopup.instance.show();
      }
    }
    if (this.isAssigned() == undefined) {
      this.createRiskPopup.instance.show();
    }
  }

  private isAssigned(): boolean {
    let isAssigned = false;
    if (this.assignedRisks?.length !== 0) {
      this.assignedRisks.forEach((element) => {
        if (element.riskIssue?.title === this.selectedRisk?.title)
          isAssigned = true;
      });
    }
    return isAssigned;
  }

  addCustomRisk(data) {
    this.selectedRisk = null;
    this.addedRisk = data;
    if (!data.text) {
      data.customItem = null;
      return;
    }
    const taskIds = this.measureTasks.map(function (item) {
      return item.id;
    });
    const incrementedId = Math.max.apply(null, taskIds) + 1;
    this.newRisk = {
      title: data.text,
      id: incrementedId
    };
    data.customItem = this.newRisk;
    return this.newRisk;
  }

  selectRisk(event) {
    this.selectedRisk = event.selectedItem;
    this.addedRisk = null;
  }
  assignTaskToMeasure(event) {
    let query = this.measureService.prepareRiskPersistObject(event.data, this.measure);
    const multilingualProperties = this.measureTasksDefinition.multilingualProperties;
    const listProperties = this.measureTasksDefinition.listProperties;
    const object = this.dataService.createPersistObject(query, multilingualProperties, listProperties);
    this.persistenceService.addObjectForInsert(TypeKey.measureTask, object).subscribe(() => {
      this.dataService.appService.callNotification({ message: 'Erfolg', type: ToastType.SUCCESS });
    }, error => {
      this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
    });
  }

  updateTask(event) {
    let query = this.measureService.prepareRiskPersistObject(event.data, this.measure);
    const multilingualProperties = this.measureTasksDefinition.multilingualProperties;
    const listProperties = this.measureTasksDefinition.listProperties;
    const object = this.dataService.createPersistObject(query, multilingualProperties, listProperties);
    this.persistenceService.addObjectForUpdate(event.data.id, object).subscribe(() => {
      this.dataService.appService.callNotification({ message: 'Erfolg', type: ToastType.SUCCESS });
    }, error => {
      this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
    });
  }

  deleteTask(event) {
    this.persistenceService.addObjectForDelete(event.data.id).subscribe(() => {
      this.dataService.appService.callNotification({ message: 'Objekt gelöscht', type: ToastType.INFO });
    }, error => {
      this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
    });
  }

  public setResponsible(e) {
    return e ? e.id : null;
  }

  public getResponsibleFullName(responsible: ProfileInfo) {
    if (responsible) {
      return responsible.lastname + ', ' + responsible.firstname;
    }
  }
  public onClosePopup(): void {
    this.createRiskPopup.instance.hide();
  }

  public removeRow(event): void {
    this.persistenceService.addObjectForDelete(event.data.id || event.data.riskIssue.id).subscribe(() => {
      this.dataService.appService.callNotification({ message: 'Objekt gelöscht', type: ToastType.INFO });
    }, error => {
      this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
    });
  }

  public updateRow(event): void {
    const query = this.dataService.prepareRiskAssessmentUpdate(event.data);
    this.persistenceService.addObjectForUpdate(event.data.id, query).subscribe(() => {
      this.dataService.appService.callNotification({ message: 'Erfolg', type: ToastType.SUCCESS });
    }, error => {
      this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
    });
    // const riskDetail = new RiskAssessment(event.data.riskIssue);
    // this.persistenceService.addObjectForUpdate
    // this.dataService.sendRiskDetail({
    //   riskAssessment: riskDetail,
    //   riskDefinition: this.riskService.riskAssessmentDefinitionVM
    // });
    // this.router.navigate(['riskAssessmentDetail', riskDetail.id]);
  }


  public backToMeasureList(): void {
    if (this.dataService.getClickOrigin()?.source == Source.RiskDetail) {
      this.router.navigate(['riskAssessmentDetail', this.dataService.getClickOrigin().id]);
      this.dataService.setClickOrigin({ id: this.measure.id, source: Source.MeasureDetail, value: true });
    }
    else {
      this.router.navigateByUrl('measures');
    }
  }
}