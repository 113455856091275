import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CoreConfigService } from '@wissenswerft/core/configuration';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConvertHelper } from './helpers';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private dynamicContentItemUri: string;
  private itemSocialMetaDataUri: string;
  private commentsUri: string;
  private commentsByItemIdUri: string;
  private itemsUri: string;
  private defaultDefinitionByTypeKeyUri: string;
  private accountsUri: string;
  private spoqlItemsUri: string;
  private enumPropertyUri: string;
  public scope: string = localStorage.getItem('scopeKey') || 'com1';
  private scopeName = new Subject()
  public scopeName$ = this.scopeName.asObservable();

  constructor(private http: HttpClient, private configService: CoreConfigService) {
    this.dynamicContentItemUri = this.configService.configuration.WebApi.ServiceURLs.DynamicContentItem;
    this.itemSocialMetaDataUri = this.configService.configuration.WebApi.ServiceURLs.ItemSocialMetaData;
    this.itemsUri = this.configService.configuration.WebApi.ServiceURLs.Items;
    this.defaultDefinitionByTypeKeyUri = this.configService.configuration.WebApi.ServiceURLs.DefaultDefinitionByTypeKey;
    this.accountsUri = this.configService.configuration.WebApi.ServiceURLs.Accounts;
    this.spoqlItemsUri = this.configService.configuration.WebApi.ServiceURLs.SPOQL_Items;
    this.commentsUri = this.configService.configuration.WebApi.ServiceURLs.Comments;
    this.commentsByItemIdUri = this.configService.configuration.WebApi.ServiceURLs.CommentsByItemId;
    this.enumPropertyUri = this.configService.configuration.WebApi.ServiceURLs.EnumProperty;

  }

  public getAllComments<T>(): Observable<T> {
    return this.executeWebApiGetMethod<T>(ConvertHelper.resolveStringPlaceholders(this.commentsUri, this.scope));
  }

  public getCommentsByItemId<T>(id): Observable<T> {
    return this.executeWebApiGetMethod<T>(ConvertHelper.resolveStringPlaceholders(this.commentsByItemIdUri, this.scope, id));
  }

  public getDynamicContentItemById<T>(id): Observable<T> {
    return this.executeWebApiGetMethod<T>(ConvertHelper.resolveStringPlaceholders(this.dynamicContentItemUri, this.scope, id));
  }

  public getSocialMetaDataByItemId<T>(id): Observable<T> {
    return this.executeWebApiGetMethod<T>(ConvertHelper.resolveStringPlaceholders(this.itemSocialMetaDataUri, this.scope, id));
  }

  public getItemsByName<T>(name: TypeKey): Observable<T> {
    return this.executeWebApiGetMethod<T>(ConvertHelper.resolveStringPlaceholders(this.itemsUri, this.scope, name));
  }

  public getDefinitonByTypeKey<T>(name: TypeKey): Observable<T> {
    return this.executeWebApiGetMethod<T>(ConvertHelper.resolveStringPlaceholders(this.defaultDefinitionByTypeKeyUri, this.scope, name));
  }

  public getAllAccounts<T>(): Observable<T> {
    return this.executeWebApiGetMethod<T>(ConvertHelper.resolveStringPlaceholders(this.accountsUri, this.scope)).pipe(
      map((users: any) => {
        users = users.reduce((unique, o) => {
          if (!unique.some((obj) => obj.id === o.id)) {
            unique.push(o);
          }
          return unique;
        }, []);
        return users.sort((a, b) => a.lastname.localeCompare(b.lastname));
      }));
  }

  public getItemsBySpoqlQuery<T>(queryTable, whereCondition): Observable<T> {
    return this.executeWebApiGetMethod<T>(this.spoqlItemsUri, { 'q': this.getSPOQLQuery(this.scope, queryTable, whereCondition) });
  }

  public getEnumProperty<T>(name: TypeKey, property: string): Observable<T> {
    return this.executeWebApiGetMethod<T>(ConvertHelper.resolveStringPlaceholders(this.enumPropertyUri, this.scope, name, property));
  }

  private executeWebApiGetMethod<T>(url: string, params?: any): Observable<T> {
    return this.http.get<T>(url, { params: params });
  }

  private getSPOQLQuery(scope, selectTable, whereCondition) {
    return `at '${scope}' select item from '${selectTable}' where { ${whereCondition} }`;
  }

  public setScope(scope): void {
    this.scope = scope;
  }

  public getScope(): string {
    return this.scope;
  }

  public executeWebApiPostMethod(url: string, body: any): Observable<any> {
    return this.http.post(url, body);
  }

  public prepareTitleScope(scope: string) {
    this.scopeName.next(scope);
  }

  public executeWebApiPatchMethod(url, body) {
    this.http.patch(url, body);
  }

  public executeWebApiDeleteMethod(url): Observable<any> {
    return this.http.delete(url);
  }

  public executeWebApiPutMethod(url, body): Observable<any> {
    return this.http.put(url, body);
  }
}

export enum TypeKey {
  module = 'module',
  riskAssessment = 'riskIssue',
  riskAssessmentMeasure = 'riskAssessmentMeasure',
  subModule = 'subModule',
  measure = 'measure',
  measureBenefit = 'measureBenefit',
  measureType = 'typeOfMeasure',
  measureClassification = 'measureClassification',
  measureTask = 'measureTask',
  measureDefinition = 'measureDefinition',
  standard = 'standard',
  responsiblePlan = 'responsiblePlan',
  riskReduction = 'riskReduction',
  damageKind = 'damageKind',
  country = 'country',
  tag = 'tag',
  product = 'product',
  legalBasis = 'legalBasis'
}