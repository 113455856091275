import { DamageKind, Country, NamedItem, Product, Standard, SubModule, Tag, LegalBasis } from "./catalog-library.model";

export class RiskIssue extends NamedItem {
    question: string;
    description: string; // RichText
    tags: Tag[];
    subModule: SubModule;
    standard: Standard[];
    damageKinds: DamageKind[];
    products: Product[];
    legalBasis: LegalBasis[];
    countries: Country[];

    constructor(data) {
        super();
        Object.assign(this, data);
    }
}



