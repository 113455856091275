import { Component, OnInit } from '@angular/core';
import { TypeKey } from '@wissenswerft/core/data';

@Component({
  selector: 'tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss']
})
export class TagComponent implements OnInit {
  public typeKey: TypeKey = TypeKey.tag;

  constructor() { }

  ngOnInit(): void {

  }

}
