export enum Mode {
    Multiple = "multiple",
    None = "none",
    single = "single",
}

export enum ScrollMode {
    Virtual = "virtual",
    Infinite = "infinite",
    Standard = "standard",
}

export enum ShowScrollbar {
    Always = "always",
    Never = "never",
    OnHover = "onHover",
    OnScroll = "onScroll",
}

export enum RowMode {
    Virtual = "virtual",
    Standard = "standard",
}

export enum ShowMode {
    Always = "always",
    none = "none",
    onClick = "onClick",
    onLongTap = "onLongTap",
}

export enum SortOrder {
    None = "undefined",
    Ascending = "asc",
    Descending = "desc"
}

export enum StateStorageType {
    LocalStorage = "localStorage",
    SessionStorage = "sessionStorage",
    Custom = "custom",
}

export enum EditingMode {
    Batch = "batch",
    Cell = "cell",
    Row = "row",
    Form = "form",
    PopUp = "popup"
}

export enum ColumnChooserMode {
    DragAndDrop = "dragAndDrop",
}

export enum dxColumnDataType {
    string = 'string',
    number = 'number',
    date = 'date',
    boolean = 'boolean',
    object = 'object',
    datetime = 'datetime'
}

export enum dxColumnType {
    adaptive = 'adaptive',
    buttons = 'buttons',
    detailExpand = 'detailExpand',
    groupExpand = 'groupExpand',
    selection = 'selection'
}

export const FilterConditions = {
    string: ["contains", "anyof", "notcontains", "startswith", "endswith", "=", "<>"],
    object: ["=", "<>", "<", ">", "<=", ">=", "between"]
};
