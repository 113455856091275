import { Injectable } from '@angular/core';
import { AccessibleScopes } from '@wissenswerft/core/authentication';
import { Subject } from 'rxjs';
import { HomeMode } from './core/models/home.model';
import { IToast } from './core/shared/ww-toast/toast.model';
import { AccessibleScopesViewModel } from './core/view-models/customer-space.view-model';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  public homeMode: HomeMode = HomeMode.company;
  private notificationSubject: Subject<IToast> = new Subject();
  public notification$ = this.notificationSubject.asObservable();
  private accessibleScopes: AccessibleScopesViewModel;
  constructor() { }

  public getHomeMode(): HomeMode {
    return this.homeMode;
  }

  public setHomeMode(homeMode: HomeMode): void {
    this.homeMode = homeMode;
  }

  public callNotification(notification : IToast): void {
    this.notificationSubject.next(notification);
  }

  public getAccessibleScopes(scopeKey, accessibleScopes?: AccessibleScopes[]) {
    if (!this.accessibleScopes) {
      this.accessibleScopes = new AccessibleScopesViewModel(accessibleScopes);
    }
    return this.accessibleScopes.scopeByName[scopeKey];
  }
  
}
