import { MeasureRecommendation } from "./measure-recommendation.model";
import { RiskIssue } from "./risk-issue.model";
import { ProfileInfo } from "@wissenswerft/core/authentication";

export class CatalogLibraryBase {
    id: number;
    ident: string;
}

export class NamedItem extends CatalogLibraryBase {
    title?: string;
    label?: string;
}

export class Standard extends NamedItem {
    description: string;
    measures: MeasureRecommendation[];
    riskIssues: RiskIssue[];
}


export class SubModule extends CatalogLibraryBase {
    ident: string;
    label: string;
    module : number;
    constructor(data) {
        super();
        Object.assign(this, data);
    }
}
export class Module extends CatalogLibraryBase {
    ident: string;
    label: string;
    submodules: SubModule[];

    constructor(data) {
        super();
        Object.assign(this, data);
        if (data?.submodules) {
            this.submodules = [];
            for (let index = 0; index < data.submodules.length; index++) {
                const property = data.submodules[index];
                this.submodules.push(property);
            }
        }
    }
}

export class LegalBasis extends NamedItem {
    description: string;
    link : string;
}

export class ResponsiblePlan {
    plannedDays: number;
    remainingDays: number;
    responsible: ProfileInfo;
    measure: MeasureRecommendation;

    constructor(data) {
        Object.assign(this, data);
    }
}

export class RiskAssesmentMeasure {
    riskIssue : RiskIssue;
    measure : MeasureRecommendation;
    constructor(data) {
        Object.assign(this, data);
    }
}

export class Tag extends NamedItem {

}

export class Product extends NamedItem {

}

export class Country extends NamedItem {

}

export class MeasureDefinition extends NamedItem {

}
export class MeasureBenefit extends NamedItem {
    constructor(data) {
        super();
        Object.assign(this,data);
    }

}

export class DamageKind extends NamedItem {

}