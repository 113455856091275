import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { NamedItem } from '@wissenswerft/cmt/catalog-library';
import { PersistenceService, TypeKey } from '@wissenswerft/core/data';
import { DxPopupComponent } from 'devextreme-angular';
import { Column } from 'devextreme/ui/data_grid';
import { Observable, Subscription } from 'rxjs';
import { DataService } from '../../../shared/data.service';
import { GridComponent } from '../../shared/ww-grid/grid.component';
import { ToastType } from '../../shared/ww-toast/toast.model';
import { WindowLayoutComponent } from '../../shared/ww-window-layout/windowLayout.component';
import { DataDefinitionViewModel } from '../../view-models/customer-space.view-model';
import { NamedItemViewModel, VISIBLE_PROPERTIES } from '../../view-models/named-item.view-model';

@Component({
    selector: 'named-item',
    templateUrl: './named-item.component.html',
    styleUrls: ['./named-item.component.scss']
})
export class NamedItemComponent implements OnInit, AfterViewInit, OnInit {
    @ViewChild('namedItemGrid') namedItemGrid: GridComponent;
    @ViewChild('createTagPopup') createTagPopup: DxPopupComponent;
    @ViewChild('windowLayout') windowLayout: WindowLayoutComponent;
    @Input('typeKey') typeKey: TypeKey;
    @Input('id') id: string;
    public gridData: NamedItemViewModel[];
    public title: string;
    public columnsHeader: Column[] = [];
    public namedItem: NamedItem;
    private subscriptions: Subscription[] = [];

    constructor(public dataService: DataService,
        private persistenceService: PersistenceService) { }


    ngOnInit(): void {
        this.namedItem = new NamedItem();
        this.prepareNamedItemData(this.typeKey);
    }

    public prepareNamedItemData(typeKey: TypeKey): void {
        if (this.dataService.definitionsVM[TypeKey.tag]) {
            this.title = this.dataService.definitionsVM[TypeKey.tag].namePlural;
        }
        this.subscriptions.push(this.dataService.getDefinitionAndData<NamedItem[]>(typeKey).subscribe(data => {
            this.gridData = [];
            this.columnsHeader = [];
            const definitions = data[0];
            this.dataService.definitionsVM[typeKey] = definitions;
            this.title = definitions.namePlural;
            const namedItems = data[1];
            const definitionVM = new DataDefinitionViewModel(definitions, VISIBLE_PROPERTIES);
            this.dataService.definitionsVM[typeKey].definitionVM = definitionVM;
            const properties = definitionVM.properties;
            for (const key in properties) {
                if (properties[key]) {
                    const property = properties[key];
                    this.columnsHeader.push({
                        dataField: property.key,
                        caption: property.label,
                        visible: property?.visible,
                        dataType: this.dataService.getDataType(property.type)
                    });
                }
            }
            namedItems.forEach((namedItem: NamedItem) => {
                const namedItemViewModel = new NamedItemViewModel(namedItem);
                this.gridData.push(namedItemViewModel);
            });
        }));

        this.subscriptions.push(this.dataService.updateGridData$.subscribe((tag: NamedItemViewModel) => {
            this.gridData.push(tag);
        }));
    }

    ngAfterViewInit(): void {
        this.subscriptions.push(this.windowLayout.onSaving.subscribe(() => {
            this.createNamedItem();
        }));
    }

    public prepareNamedItemPersistObject(namedItem: NamedItemViewModel): NamedItem {
        const query: NamedItem = new NamedItem();
        query.label = namedItem.label;
        query.ident = namedItem.ident.replace(/\s/g, '');
        return query;
    }

    public removeRow(event): void {
        const namedItemId = event.data.id || event.data.namedItem.id;
        this.subscriptions.push(this.persistenceService.addObjectForDelete(namedItemId).subscribe(() => {
            this.dataService.appService.callNotification({ message : 'Objekt gelöscht', type: ToastType.INFO });
        }, error => {
            this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
        }));
    }

    public rowUpdated(event): void {
        const namedItemId = event.data.id || event.data.namedItem.id;
        const namedItem: NamedItemViewModel = new NamedItemViewModel(event.data);
        const query = this.prepareNamedItemPersistObject(namedItem);
        const multilingualProperties = this.dataService.definitionsVM[this.typeKey].definitionVM.multilingualProperties;
        const listProperties = this.dataService.definitionsVM[this.typeKey].definitionVM.listProperties;
        const object = this.dataService.createPersistObject(query, multilingualProperties, listProperties);
        this.subscriptions.push(this.persistenceService.addObjectForUpdate(namedItemId, object).subscribe(() =>{
            this.dataService.appService.callNotification({ message : 'Erfolg', type: ToastType.SUCCESS });
        }, error => {
            this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
        }));
    }

    private createNamedItem(): void {
        this.persistObject().subscribe((data: NamedItemViewModel) => {
            this.dataService.appService.callNotification({ message : 'Erfolg', type: ToastType.SUCCESS });
            this.dataService.updateGridData(data);
        }, error => {
            this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
        });
        this.namedItem = new NamedItem();
    }

    private persistObject(): Observable<any> {
        const multilingualProperties = this.dataService.definitionsVM[this.typeKey].definitionVM.multilingualProperties;
        const listProperties = this.dataService.definitionsVM[this.typeKey].definitionVM.listProperties;
        const query = this.dataService.createPersistObject(this.namedItem, multilingualProperties, listProperties);
        return this.persistenceService.addObjectForInsert(this.typeKey, query);
    }

    public openTagDialog(): void {
        this.createTagPopup.instance.show();
    }

    public onClosePopup(): void {
        this.createTagPopup.instance.hide();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => { subscription.unsubscribe(); });
    }

}
