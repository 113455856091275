import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { IdeTimerService } from '../services/idleTimer.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loading = false;
  formData: any = {};
  Form: FormGroup;
  errorExist = false;

  constructor(private userService: UserService, private router: Router, private ideTimerService:IdeTimerService) { }

  ngOnInit(): void {
    this.userService.clearLoginInfo();
  }

  onSubmit(e) {
    this.userService.login(this.formData).subscribe(() => {
      this.router.navigate(['/']).then(_ => console.log('You are secure now!'));
    }, (err: any) => {
      console.log(err);
    });
  }

}