import { SubModule } from "@wissenswerft/cmt/catalog-library";

export class ScopeViewModel {
    private scope: SubModule;

    get ident() { return this.scope.ident; }
    get label() { return this.scope.label; }
    get module() { return this.scope.module; }
    set ident(data) { this.scope.ident = data; }
    set label(data) { this.scope.label = data; }
    set module(data) { this.scope.module = data; }
    constructor(data: SubModule) {
        this.scope = data;
    }
}

export const VISIBLE_PROPERTIES = [
    'ident', 'label', 'module'
];