import { ProfileInfo } from '@wissenswerft/core/authentication';

export class Comment {
    id: number;
    additionalContexts?: string[];
    attachments?: [];
    author: ProfileInfo;
    created: Date;
    lastEdited?: Date;
    mainContext?: string;
    mainContextLabel?: string;
    state?: any; 
    text: string;
    
    constructor(data) {
        Object.assign(this, data);
    }
}