import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TokenService } from '../services/token.service';
import { ConfigService } from 'libs/core/configuration/src/lib/config.service';

@Injectable()
export class Interceptor implements HttpInterceptor {

    constructor(private tokenService: TokenService, private configService: ConfigService) {
    }
    intercept(request: HttpRequest<any>, next: HttpHandler): any {
        const token = this.tokenService.getToken();
        if (token && request.url !== this.configService?.configuration?.WebApi?.ServiceURLs.Account_Refresh_Token) {
            request = request.clone({
                setHeaders: {
                    Authorization: 'Bearer ' + token
                }
            });
            if (!request.headers.has('Content-Type')) {
                request = request.clone({
                    setHeaders: {
                        'content-type': 'application/json',
                        'Accept': 'application/json'
                    }
                });
            }
        }

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                console.log(error);
                return throwError(error);
            }));
    }
}