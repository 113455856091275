import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of, Subject } from 'rxjs';
import { RiskAssessment } from '../core/models/risk-assesment.model';
import { Measure } from '../core/models/measure.model';
import { CoreDataService, TypeKey } from '@wissenswerft/core/data';
import { DataDefinition, RiskAssesmentMeasure } from '../core/models/customer-space.model';
import { DataDefinitionViewModel } from '../core/view-models/customer-space.view-model';
import { concatMap, map } from 'rxjs/operators';
import { ClickOrigin } from '../core/models/home.model';
import { AppService } from '../app.service';
export interface RiskDataAndDefinition {
  riskAssessment: RiskAssessment;
  riskDefinition: DataDefinitionViewModel;
}
@Injectable()
export class DataService {
  private riskAssessmentDetailSubject = new BehaviorSubject<RiskDataAndDefinition>(null);
  private measureDetailSubject = new BehaviorSubject<Measure>(null);
  private gridDataSubject = new Subject<any>();
  public updateGridData$ = this.gridDataSubject.asObservable();
  public definitionsVM: DataDefinitionViewModel[] = [];
  public clickOrigin: ClickOrigin;

  constructor(private dataService: CoreDataService, public appService : AppService) {
  }

  public sendRiskDetail(risk: RiskDataAndDefinition) {
    this.riskAssessmentDetailSubject.next(risk);
  }

  public getRiskDetail(): Observable<RiskDataAndDefinition> {
    return this.riskAssessmentDetailSubject.asObservable();
  }

  public sendMeasureDetail(measureAssessment: Measure) {
    this.measureDetailSubject.next(measureAssessment);
  }

  public getMeasureDetail(): Observable<Measure> {
    return this.measureDetailSubject.asObservable();
  }

  public getDefinitionAndData<T>(typeKey: TypeKey): Observable<[DataDefinition, T]> {
    if (this.definitionsVM[typeKey]) {
      return forkJoin([of(this.definitionsVM[typeKey]), this.dataService.getItemsByName<T>(typeKey)]);
    }
    else {
      return forkJoin([this.dataService.getDefinitonByTypeKey<DataDefinition>(typeKey), this.dataService.getItemsByName<T>(typeKey)]);
    }
  }

  public updateGridData(data: any) {
    this.gridDataSubject.next(data);
  }

  public getRiskAssessmentMeasures() {
    const queries = [];
    return this.dataService.getItemsByName<RiskAssesmentMeasure[]>(TypeKey.riskAssessmentMeasure)
      .pipe(concatMap(rAMeasures => {
        queries.push(of(rAMeasures));
        rAMeasures.forEach(rAMeasure => {
          if (rAMeasure?.measure) {
            queries.push(this.dataService.getDynamicContentItemById<Measure>(rAMeasure.measure).pipe(map(measure => {
              rAMeasure.measure = new Measure(measure);
            })));
          }
          if (rAMeasure?.riskIssue) {
            queries.push(this.dataService.getDynamicContentItemById<RiskAssessment>(rAMeasure.riskIssue).pipe(map(risk => {
              rAMeasure.riskIssue = new RiskAssessment(risk);
            })));
          }
        });
        return forkJoin(queries);
      }));
  }

  public createPersistObject(data, multilingualProperties, listProperties) {
    const query = data;
    multilingualProperties.forEach(prop => {
      if (query.hasOwnProperty(prop.key)) {
        query[prop.key] = { "de": query[prop.key], 'en': query[prop.key] };
      }
    });
    listProperties.forEach(prop => {
      if (query.hasOwnProperty(prop.key)) {
        if (!query[prop.key]?.length) {
          query[prop.key] = [query[prop.key]];
        }
      }
    });
    return query;
  }

  public getClickOrigin(): ClickOrigin {
    return this.clickOrigin;
  }

  public setClickOrigin(source: ClickOrigin): void {
    this.clickOrigin = source;
  }

  public checkGridGroupByColumns(key : string, groupByColumnIndex) {
    if (localStorage.getItem(key)) {
      const gridState = JSON.parse(localStorage.getItem(key));
      gridState.columns.map(item => {
        if (item.groupIndex) {
          groupByColumnIndex[item.dataField] = item.groupIndex
          return groupByColumnIndex;
        }
      })
    }
  }

  public getDataType(dataType) {
    switch (dataType) {
      case 'TEXT':
        return 'string';
      case 'NUMBER':
        return 'number';
      case 'LOCALDATE':
        return 'date';
      case 'BOOLEAN':
        return 'boolean';
      default:
        return 'string';
    }
  }

  public prepareRiskAssessmentUpdate(data): RiskAssesmentMeasure {
    const query: RiskAssesmentMeasure = new RiskAssesmentMeasure(null);
    query.measure = data.measure?.id;
    query.riskIssue = data.riskIssue?.id;
    query['id'] = data?.id;
    query['type'] = data?.type;
    query['value'] = data?.value;
    return query;
  }
}
