import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Comment } from '../models/comment.model';
import { PersistenceService } from '@wissenswerft/core/data';
import { ProfileInfo, UserService } from '@wissenswerft/core/authentication';
import { DxButtonComponent, DxListComponent, DxTextAreaComponent } from 'devextreme-angular';

@Component({
  selector: 'comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit {
  @ViewChild('commentTextArea') dxTextArea: DxTextAreaComponent;
  @ViewChild('commentlist') dxlist: DxListComponent;
  @ViewChild('saveButton') dxbutton: DxButtonComponent;


  @Input() comments: Comment[] = [];
  @Input() itemId: number;
  @Input() height: string;
  public flag: boolean = false;
  public comment: Comment = new Comment(null);
  private user: ProfileInfo;
  public commentText: string;

  constructor(private persistenceService: PersistenceService, private userService: UserService,) {
  }

  ngOnInit(): void {
    this.userService.getProfileInformations().subscribe(data => {
      this.user = data;
    })
  }

  public addComment(): void {
    if (this.dxbutton.icon === 'add') {
      this.comment.text = this.commentText;
      this.persistenceService.addComment(this.comment, this.itemId).subscribe(data => {
        if (!this.comments) this.comments = [];
        this.comments.push(data);
      });
      this.flag = false;
      this.dxTextArea.instance.reset();
      this.dxlist.instance.reload();
    } else {
      // this.persistenceService.updateComment(this.itemId, this.comment).subscribe(data =>{
      //   this.dxlist.instance.reload();
      // });
      // this.dxTextArea.instance.reset();
      // this.flag = false;
      // this.dxbutton.icon ='add';
    }
  }

  public deleteComment(event): void {
    this.persistenceService.deleteComment(event.itemData.id, this.itemId).subscribe();
  }

  public updateComment(event): void {
    // this.flag = true;
    // this.dxbutton.icon = 'save'
    // this.dxTextArea.value = event.itemData.text;
    // if (this.dxTextArea.value != event.itemData.text) {
    //   this.comment.text = this.dxTextArea.value;
    // }
  }

  public calculateDuration(commentDate): number {
    const difference = Date.now() - Date.parse(commentDate);
    const hours = Math.floor(difference / (60 * 60 * 1000));
    return hours;
  }
}