import { Injectable } from '@angular/core';
import { Country, Module, SubModule } from '@wissenswerft/cmt/catalog-library';
import { ProfileInfo } from '@wissenswerft/core/authentication';
import { CoreDataService, TypeKey } from '@wissenswerft/core/data';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AmountOfDamage, PropertyEnum, Status, TypeOfDamage } from '../../models/risk-assesment.model';
import { DataDefinitionViewModel } from '../../view-models/customer-space.view-model';

@Injectable({
  providedIn: 'root'
})
export class RiskAssessmentService {
  private _responsibles: Observable<ProfileInfo[]>;
  public responsibleById: {};
  private _scopes: Observable<SubModule[]>;
  public scopeById: {};
  private _countries: Observable<Country[]>;
  public countryById: {};
  private _standards: Observable<SubModule[]>;
  public standardById: {};
  public probability: EnumValues[] = [
    { label: AmountOfDamage.low, value: 1 },
    { label: AmountOfDamage.medium, value: 3 },
    { label: AmountOfDamage.high, value: 5 },
    { label: AmountOfDamage.veryhigh, value: 7 }
  ]
  public amountOfDamage: EnumValues[] = [
    { label: AmountOfDamage.low, value: 1 },
    { label: AmountOfDamage.medium, value: 3 },
    { label: AmountOfDamage.high, value: 5 },
    { label: AmountOfDamage.veryhigh, value: 7 }
  ]
  public typeOfDamage = [
    { label: 'Immaterial', value: TypeOfDamage.IMMATERIAL },
    { label: 'Material', value: TypeOfDamage.MATERIAL },
  ]
  public status = [
    { label: 'Open', value: Status.open },
    { label: 'Bewertet', value: Status.bewertet },
    { label: 'Planned', value: Status.planned },
    { label: 'measuresDefined', value: Status.measuresDefined }
  ]

  public namedStatus = {};
  
  public riskAssessmentDefinitionVM: DataDefinitionViewModel;
  private _modules: Module[];
  private _modulesById: NamedModule = {};
  private _selectedModuleSubject = new BehaviorSubject<Module>(null);


  constructor(private coreDataService: CoreDataService) {
    this.LocalizeEnums();
  }

  public prepareModules() {
    this.coreDataService.getItemsByName<Module[]>(TypeKey.module).subscribe({
      next: modules => {
        this._modules = modules;
        this._modules.forEach((module =>{
          this._modulesById[module.id] = new Module(module);
        }))
        // this.setSelectedModule(modules[0]);
      }
    });
  }

  private LocalizeEnums() {
    this.coreDataService.getEnumProperty<PropertyEnum[]>(TypeKey.riskAssessment, 'amountOfDamage').subscribe((data) => {
      this.amountOfDamage.forEach((val, i) => {
        this.amountOfDamage[i].label = data[val.value].de;
      })
    })
    this.coreDataService.getEnumProperty<PropertyEnum[]>(TypeKey.riskAssessment, 'probability').subscribe((data) => {
      this.probability.forEach((val, i) => {
        this.probability[i].label = data[val.value].de;
      })
    })
    this.coreDataService.getEnumProperty<PropertyEnum[]>(TypeKey.riskAssessment, 'status').subscribe((data) => {
      for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          const element = data[key];
          this.status.forEach((status, i) => {
            if (status.value === key) {
              this.namedStatus[status.value] = element.de;
              this.status[i].label = element.de;
            }
          })
          
        }
      }
    })
  }

  public get responsibles(): Observable<ProfileInfo[]> {
    if (!this._responsibles) {
      this._responsibles = this.coreDataService.getAllAccounts<ProfileInfo[]>().pipe(map(users => {
        this.responsibleById = {};
        users.forEach((user => {
          user = new ProfileInfo(user);
          this.responsibleById[user.id] = user;
        }));
        return users;
      }));
    }
    return this._responsibles;
  }

  public set responsibles(data) {
    this._responsibles = data;
  }

  public get modules(): Module[] {
    return this._modules;
  }

  public getRsponsibleFullName(responsible: ProfileInfo) {
    return `${responsible.lastname}, ${responsible.firstname}`;
  }

  public setSelectedModule(module: Module) {
    return this._selectedModuleSubject.next(module);
  }
  public getSelectedModule(): Observable<Module> {
    return this._selectedModuleSubject.asObservable();
  }

  public get scopes(): Observable<SubModule[]> {
    if (!this._scopes) {
      this._scopes = this.coreDataService.getItemsByName<SubModule[]>(TypeKey.subModule).pipe(map(scopes => {
        this.scopeById = {};
        scopes.forEach((scope => {
          scope = new SubModule(scope);
          this.scopeById[scope.id] = scope;
        }));
        return scopes;
      }));
    }
    return this._scopes;
  }

  public get countries(): Observable<Country[]> {
    if (!this._countries) {
      this._countries = this.coreDataService.getItemsByName<Country[]>(TypeKey.country).pipe(map(countries => {
        this.scopeById = {};
        countries.forEach((country => {
          country = new Country();
          this.scopeById[country.id] = country;
        }));
        return countries;
      }));
    }
    return this._countries;
  }

  public get moduleById(): NamedModule {
    if (!this._modulesById) {
      this.prepareModules();
    }
    return this._modulesById;
  }

}

export interface EnumValues {
  label: string;
  value: number;
}

export interface NamedModule {
  [key: string]: Module
}