import { Standard, Tag } from "@wissenswerft/cmt/catalog-library";
import { ProfileInfo } from "@wissenswerft/core/authentication";
import { AmountOfDamage, RiskAssessment } from "../models/risk-assesment.model";

export class RiskAssessmentViewModel {
    protected riskAssessment: RiskAssessment;
    private _responsibleFullName: string;
    get title() { return this.riskAssessment.title; }
    get label() { return this.riskAssessment.label; }
    get needsClarification(): boolean { return this.riskAssessment.needsClarification; }
    get description(): string { return this.riskAssessment.description; }
    get existenceThreatening(): boolean { return this.riskAssessment.existenceThreatening; }
    get relevant(): boolean { return this.riskAssessment.relevant; }
    get status(): string { return this.riskAssessment.status; }
    get standard(): Standard[] { return this.riskAssessment.standard; }
    get probability() { return this.riskAssessment.probability ?? 0; }
    get typeOfDamage() { return this.riskAssessment.typeOfDamage; }
    get countries() { return this.riskAssessment.countries; }

    get amountOfDamage(): number { return this.riskAssessment.amountOfDamage ?? 0; }
    get responsible() {
        if (this.riskAssessment?.responsible) {
            this._responsibleFullName = this.riskAssessment?.responsible.firstname + ', ' + this.riskAssessment?.responsible.lastname
            return this.riskAssessment?.responsible.id;
        }
    }
    // get responsible(): string[] {
    //     if (!this._responsibleFullName) {
    //         this.riskAssessment.responsible.forEach(user => {
    //             if (!this._responsibleFullName) this._responsibleFullName = [];
    //             this._responsibleFullName.push(`${user.lastname}, ${user.firstname}`);
    //         });
    //     }
    //     return this._responsibleFullName;
    // }
    get subModule() { return this.riskAssessment.subModule?.label; }
    get question() { return this.riskAssessment.question; }
    get tags(): Array<Tag> { return this.riskAssessment.tags; }

    get module() { return this.riskAssessment.module?.label }

    get riskAverage() {
        return this.amountOfDamage * this.probability; 
    }

    constructor(data: RiskAssessment) {
        this.riskAssessment = data;
    }
}

export const VISIBLE_PROPERTIES = [
    'question', 'responsible', 'description', 'amountOfDamage', 'probability', 'subModule', 'module'
];
