import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TypeKey } from '@wissenswerft/core/data';
import { DataService } from '../../shared/data.service';
import { RiskAssessment } from '../models/risk-assesment.model';
import { FieldArea } from '../shared/ww-matrix-grid/matrix-grid.model';
import { Damage, DashBoardMeasure, DashBoardRisk, MatrixViewKey, RiskDistribution } from '../models/dashboard.model';
import { MatrixComponent } from '../shared/ww-matrix-grid/matrix-grid.component';
import { Subscription } from 'rxjs';
import { RiskAssesmentMeasure } from '../models/customer-space.model';
import { Measure } from '../models/measure.model';
import { Column } from 'devextreme/ui/data_grid';
import { Module } from '@wissenswerft/cmt/catalog-library';


@Component({
  selector: 'cmt-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('riskMatrix') riskMatrix: MatrixComponent;
  public riskDistributionDataSource: RiskDistribution[] = [];
  private data: RiskDistribution[] = [];
  public dataSource = {};
  private subscriptions: Subscription[] = [];
  public averageRiskAmountOfDamages: number = 0;
  public measures: DashBoardMeasure[] = [];
  public risks: DashBoardRisk[] = [];

  public riskAssesmentMeasureColumns: Column[] = [
    {
      dataField: 'title', caption: 'Maßnahme', dataType: 'string'
    },
    {
      dataField: 'riskReduction', caption: 'Risikoreduktion', calculateCellValue: this.calculateCellValue, dataType: 'string'
    },
    {
      dataField: 'deadLine', caption: 'Enddatum', dataType: 'date'
    }
  ];
  public riskColumns: Column[] = [
    {
      dataField: 'module', caption: 'Modul'
    },
    {
      dataField: 'risk', caption: 'Realistische Schadenshöhe', lookup: {
        dataSource: ['Niedrig', 'Mittel', 'Hoch', 'Sehr Hoch']
      }
    }
  ];
  constructor(private dataService: DataService) {
    this.subscriptions.push(this.dataService.getDefinitionAndData<Module[]>(TypeKey.module).subscribe((data) => {
      data[1].forEach(element => {
        const dashboardRisk: DashBoardRisk = new DashBoardRisk(element.id, element.label, [], '');
        this.risks.push(dashboardRisk)
      })
    }));
  }

  ngOnInit(): void {
    this.subscriptions.push(this.dataService.getDefinitionAndData<Measure[]>(TypeKey.measure).subscribe((data) => {
      const measures: Measure[] = data[1];
      measures.forEach(measure => {
        this.measures.push(new DashBoardMeasure(measure.id, measure.title, measure.endDate, [], 0));
      });
    }));

    this.subscriptions.push(this.dataService.getRiskAssessmentMeasures().subscribe(data => {
      const riskAssessmentMeasures: RiskAssesmentMeasure[] = data[0] as RiskAssesmentMeasure[];
      riskAssessmentMeasures.map((riskAssessmentMeasure => {
        this.measures.find(element => {
          if (element.id === riskAssessmentMeasure.measure?.id) {
            element.ram.push(riskAssessmentMeasure.value)
          };
        });
      }));
      let total: number = 0;
      this.measures.map(measure => {
        measure.ram?.map(element => {
          total += element;
        });
        if (measure.ram.length !== 0) {
          measure.riskReduction = total / measure.ram.length;
        }
      })
    }));

    this.subscriptions.push(this.dataService.getDefinitionAndData<RiskAssessment[]>(TypeKey.riskAssessment).subscribe((data) => {
      const riskAssessments = data[1];
      let total: number = 0;      
      riskAssessments.forEach(risk => {
        this.risks.find(element => {
          if (element.id === risk.subModule.module) {
            element.damage.push(risk.amountOfDamage)
          }
        })
        if (risk.probability && risk.amountOfDamage) {
          this.data.push(new RiskDistribution(this.setMatrixHeader(risk.probability), this.setMatrixHeader(risk.amountOfDamage), 0));
          const avrgAD: number = (risk.amountOfDamage * 100) / 7;
          total += avrgAD;
        }
      })
      this.risks.map(element => {
        let riskTotal: number = 0;
        element.damage.map(damage => {
          const avrgAD: number = (damage * 1);
          riskTotal += avrgAD;
        });
        if (element.damage.length != 0) {
          const riskPerModule: number = riskTotal / element.damage.length;
          element.risk = this.prepareRiskLabel(riskPerModule);         
        }
      })
      if (riskAssessments.length != 0) {
        this.averageRiskAmountOfDamages = total / riskAssessments.length;
      }
      this.dataSource = {
        fields: [{
          dataField: 'probability.text',
          width: 50,
          area: FieldArea.ROW,
          sortingMethod: (a, b) => {
            return parseInt(MatrixViewKey[b.value?.replace(' ', '')]) - parseInt(MatrixViewKey[a.value?.replace(' ', '')]);
          }
        }, {
          dataField: 'damage.text',
          width: 50,
          area: FieldArea.COLUMN,
          sortingMethod: (a, b) => {
            return parseInt(MatrixViewKey[a.value?.replace(' ', '')]) - parseInt(MatrixViewKey[b.value?.replace(' ', '')]);
          }
        },
        {
          dataField: 'sum',
          area: FieldArea.DATA
        }],
        store: this.data
      }
    }));
  }

  public calculateCellValue(arg: any) {
    return arg.riskReduction + " %";
  }

  private prepareRiskLabel = (value: number): string => {
    if (value >= 1 && value < 3) {
      return Damage.LOW;
    } else if (value >= 3 && value < 5) {
      return Damage.MEDIUM;
    } else if (value >= 5 && value < 7) {
      return Damage.HIGH;
    } else if (value === 7 ) {
      return Damage.VERYHIGH;
    }
  }

  ngAfterViewInit(): void {
    this.subscriptions.push(this.riskMatrix.onCellPrepared.subscribe(event => {
      event.cellElement.height = 50;
      event.element.style.textAlign = 'center';
      if (event.area === FieldArea.DATA) {
        if (event.rowIndex === 3 || event.columnIndex === 0) {
          event.cellElement.bgColor = "lime";
        } else if (event.rowIndex === 2 || event.columnIndex === 1) {
          event.cellElement.bgColor = "yellow";
        } else if (event.rowIndex === 1 || event.columnIndex === 2) {
          event.cellElement.bgColor = "orange";
        } else {
          event.cellElement.bgColor = "red";
        }
        event.cellElement.style.textAlign = 'center';
        event.cellElement.style.borderTop = "0px !important";
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public customizeText(arg: any) {
    return arg.valueText + " %";
  }

  setMatrixHeader(value: string | number) {
    switch (value) {
      case '1': return { text: Damage.LOW, value: 1 };
      case '3': return { text: Damage.MEDIUM, value: 3 }
      case '5': return { text: Damage.HIGH, value: 5 }
      case '7': return { text: Damage.VERYHIGH, value: 7 }
    }
  }
}


