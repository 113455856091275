import { Injectable } from '@angular/core';
import { Module } from '@wissenswerft/cmt/catalog-library';
import { ModuleViewModel } from '../../view-models/module.view-model';

@Injectable({
  providedIn: 'root'
})
export class ModulesService {

  constructor() { }

  public prepareModulePersistObject(module: ModuleViewModel): Module {
    const query: Module = new Module(null);
    query.label = module.label;
    query.ident = module.ident.replace(/\s/g, '');
    query.submodules = module.subModules;
    return query;
  }
}
