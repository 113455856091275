import { ChangeDetectorRef, Component, Input, OnInit, Output, EventEmitter, ViewChild, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { DxTabPanelComponent, DxFilterBuilderComponent } from 'devextreme-angular';
import { ColumnChooserMode, EditingMode, Mode, RowMode, ScrollMode, ShowMode, ShowScrollbar, StateStorageType } from './grid.model';

@Component({
    selector: 'ww-grid',
    template: `<dx-data-grid [id]="id" #dxDataGrid 
                [dataSource]="gridData"
                [allowColumnReordering]="allowColumnReordering"
                [showBorders]="showBorders"
                [columnHidingEnabled]="columnHidingEnabled"
                [rowAlternationEnabled]="rowAlternationEnabled"
                [allowColumnResizing]="allowColumnResizing"
                [remoteOperations]="false" 
                [rtlEnabled]="rtlEnabled"
                [hoverStateEnabled]="hoverStateEnabled"
                [showColumnLines]="showColumnLines"
                [showRowLines]="showRowLines"
                [showColumnHeaders]="showColumnHeaders"
                [height]="height"
                [ngClass]="class"
                [keyExpr]="keyExpr"
                [width]="width" 
                [loadPanel]="showdefaultloadelement"
                [columns]="columnsHeader"             
                [disabled]="disabled"
                [visible]="visible"
                [summary]= "summaryData"
                [columnAutoWidth]="columnAutoWidth"
                (onOptionChanged)="optionChanged($event)"
                (onInitialized)="initialized($event)"
                (onContentReady)="ContentReady($event)"
                (onEditorPreparing)="editorPreparing($event)"
                (onEditorPrepared)="editorprepared($event)"
                (onRowPrepared)="rowPrepared($event)"
                (onCellPrepared)="cellPrepared($event)"
                (onRowClick)="rowSelect($event)"
                (onRowDblClick)="rowDbClick($event)"
                (onRowInserted)="rowInserted($event)"
                (onRowUpdated)="rowUpdated($event)"
                (onRowRemoved)="removeRow($event)"
                (onToolbarPreparing)="toolBarPreparing($event)"
                (onContextMenuPreparing)="contextMenuPreparing($event)">
                <dxo-group-panel #dxGroup [visible]="groupable"></dxo-group-panel>
                <dxo-header-filter #dxHeaderFilter [visible]="enableHeaderFilter"></dxo-header-filter>
                <dxo-filter-row #dxFilterRow [visible]="showfilterrow"></dxo-filter-row>
                <dxo-filter-panel #dxFilterPanel [visible]="filterPanel" ></dxo-filter-panel>
                <dxo-search-panel
                  [visible]="searchPanel"
                  [highlightCaseSensitive]="true"
                  [text]="text"
                  [width]="widthSearch"
                  [highlightSearchText]="highlightSearchText">
                </dxo-search-panel>
                <dxo-column-chooser [allowSearch]="columnChooserSearch" 
                [enabled]="enableColumnChooser"
                [emptyPanelText]="emptyPanelText" 
                [mode]= "columnChooserMode"
                [searchTimeout]= "columnChooserSearchTimeout">
                </dxo-column-chooser>
                <dxo-state-storing [enabled]="enableStateStorage" type={{stateStorageType}} storageKey={{id}} savingTimeout="500"></dxo-state-storing> 
                <dxo-paging [enabled]="pageable" [pageSize]="20" [pageIndex]="0"></dxo-paging>
                <dxo-pager 
                [showPageSizeSelector]="showPageSizeSelector"
                [allowedPageSizes]="allowedPageSizes"
                [showInfo]="true">
                </dxo-pager>
                <dxo-editing  [mode]="editingMode"
                [allowUpdating]="allowUpdating"
                [allowDeleting]="allowDeleting"
                [allowAdding]="allowAdding"
                [useIcons]="useIcons">
                    <dxo-popup
                    [title]="popUpTitle"
                    [showTitle]="showTitle"
                    [width]="popUpWidth"
                    [height]="popUpHeight"
                    [position]="{ my: 'center', at: 'center' }">
                    </dxo-popup>
                </dxo-editing>
                <dxo-selection
                  [mode]="selectionMode"
                  [allowSelectAll]="allowSelectAll"
                  [showCheckBoxesMode]="showCheckBoxesMode">
                </dxo-selection>
                <dxo-export [enabled]="enableExport" [allowExportSelectedData]="allowExportSelectedData"></dxo-export>
                <dxo-scrolling mode= "'virtual'"
                 [columnRenderingMode]="rowRenderingMode"
                 [rowRenderingMode]="rowRenderingMode" 
                 [preloadEnabled]="preloadEnabled" 
                 [scrollByContent]="scrollByContent" 
                 [scrollByThumb]="scrollByThumb" 
                 [showScrollbar]="showScrollBar" >
                </dxo-scrolling>
                <dxo-grouping [autoExpandAll]="autoExpandAll" [contextMenuEnabled]="groupingContextMenu"> </dxo-grouping>
                <dxo-summary>
                    <dxi-group-item
                        summaryType="count">
                    </dxi-group-item>
                </dxo-summary> 
              </dx-data-grid>`,
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.Default,
    styleUrls: ['./grid.component.scss']
})

export class GridComponent implements OnInit {
    @ViewChild('dxDataGrid', { static: true }) dxDataGrid: DxDataGridComponent;
    @ViewChild('dxGroup', { static: true }) dxGroup: DxTabPanelComponent;
    @ViewChild('dxHeaderFilter', { static: true }) dxHeaderFilter: DxFilterBuilderComponent;
    @ViewChild('dxFilterPanel', { static: true }) dxFilterPanel: DxFilterBuilderComponent;
    @ViewChild('dxFilterRow', { static: true }) dxFilterRow: DxFilterBuilderComponent;
    @Input() id: string;
    @Input() class: string;
    @Input() height: string | number = '100%';
    @Input() width: string | number;
    @Input() remoteOperations: boolean;
    @Input() pageable: boolean = true;
    @Input() showColumnHeaders: boolean = true;
    @Input() allowColumnReordering: boolean = true;
    @Input() showBorders: boolean = true;
    @Input() columnHidingEnabled: boolean = false;
    @Input() columnFilter: boolean = true;
    @Input() showfilterrow: boolean = true;
    @Input() showdefaultloadelement: boolean;
    @Input() filterPanel: boolean = true;
    @Input() groupable: boolean = true;
    @Input() showColumnLines: boolean = true;
    @Input() showRowLines: boolean = true;
    @Input() rowAlternationEnabled: boolean = true;
    @Input() exportButton: boolean = true;
    @Input() dropDownDataSource: any;
    @Input() editable: boolean = true;
    @Input() keyExpr: string;
    @Input() disabled: boolean;
    @Input() visible: boolean = true;
    @Input() autoExpandAll: boolean = true;
    @Input() columnAutoWidth: boolean;
    @Input() allowColumnResizing: boolean = true;
    @Input() hoverStateEnabled: boolean = true;
    @Input() searchPanel: boolean = true;
    @Input() placeholder: string = 'Search...';
    @Input() text: string;
    @Input() widthSearch: string;
    @Input() highlightSearchText: boolean;
    @Input() enablePaging: boolean = true;
    @Input() allowSelectAll: boolean = true;
    @Input() selectionMode: Mode = Mode.single;
    @Input() showCheckBoxesMode: ShowMode = ShowMode.Always;
    @Input() scrollMode: string = ScrollMode.Virtual;
    @Input() rowRenderingMode: RowMode.Virtual;
    @Input() showScrollBar: ShowScrollbar = ShowScrollbar.OnHover;
    @Input() autoExpand: boolean;
    @Input() enableExport: boolean = true;
    @Input() stateStorageKey: string;
    @Input() enableStateStorage: boolean = true;
    @Input() stateStorageType: StateStorageType = StateStorageType.LocalStorage;
    @Input() savingStateTimeOut: string = '500';
    @Input() enableColumnChooser: boolean = true;
    @Input() emptyPanelText: boolean = true;
    @Input() columnChooserHeight: number;
    @Input() columnChooserMode: ColumnChooserMode = ColumnChooserMode.DragAndDrop;
    @Input() columnChooserSearchTimeout: number;
    @Input() columnChooserSearch: boolean = true;
    @Input() columnChooserTitle: string = 'Column Chooser';
    @Input() columnChooserwidth: number;
    @Input() groupingContextMenu: boolean = true;
    @Input() preloadEnabled: boolean = true;
    @Input() scrollByContent: boolean = true;
    @Input() scrollByThumb: boolean = false;
    @Input() allowUpdating: boolean = true;
    @Input() allowDeleting: boolean = true;
    @Input() allowAdding: boolean = true;
    @Input() useIcons: boolean = true;
    @Input() allowExportSelectedData: boolean = true;
    @Input() useNative: boolean = true;
    @Input() showPageSizeSelector: boolean = true;
    @Input() allowedPageSizes: Array<number> = [5, 10, 20, 50, 100];
    @Input() showInfo: boolean = true;
    @Input() rtlEnabled: boolean = false;
    @Input() showTitle: boolean = true;
    @Input() popUpWidth: string | number = 'auto';
    @Input() popUpHeight: string | number = 500;
    @Input() popUpTitle: string = 'Edit';
    @Input() editingMode: EditingMode = EditingMode.PopUp;
    @Input() enableHeaderFilter: boolean = true;

    @Output() onInitialized = new EventEmitter();
    @Output() onCellPrepared = new EventEmitter();
    @Output() onSelectionChanged = new EventEmitter();
    @Output() onContentReady = new EventEmitter();
    @Output() onRowDblClick = new EventEmitter();
    @Output() onContextMenuPreparing = new EventEmitter();
    @Output() onRowInserted = new EventEmitter();
    @Output() onRowUpdated = new EventEmitter();
    @Output() onValueChanged = new EventEmitter();
    @Output() onColumnUpdate = new EventEmitter();
    @Output() onColumnSorting = new EventEmitter();
    @Output() onEditorPrepared = new EventEmitter();
    @Output() onGridScrolling = new EventEmitter();
    @Output() onOptionChanged = new EventEmitter();
    @Output() onEditorPreparing = new EventEmitter();
    @Output() onRowPrepared = new EventEmitter();
    @Output() onToolBarPreparing = new EventEmitter();
    @Output() onRowRemoved = new EventEmitter();

    public summaryData: Object = {};
    private _gridData;
    @Input()
    get gridData() { return this._gridData; }
    set gridData(gridData) {
        if (this.dxDataGrid.instance) this.dxDataGrid.instance.refresh();
        this._gridData = gridData;
        this.changeDetectorRef.detectChanges();
    }

    private _columns;
    @Input()
    get columnsHeader() { return this._columns; }
    set columnsHeader(columns) {
        this._columns = columns;
    }

    constructor(private changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {
    }

    removeRow(event) {
        this.onRowRemoved.emit(event);
    }

    public initialized(event): void {
        this.onInitialized.emit(event);
    }

    public optionChanged(event): void {
        this.onOptionChanged.emit(event);
    }

    public addRow(): void {
        this.dxDataGrid.instance.addRow();
    }

    public editorPreparing(event): void {
        this.onEditorPreparing.emit(event);
    }

    public editorprepared(event): void {
        this.onEditorPrepared.emit(event);
    }

    public ContentReady(event): void {
        this.onContentReady.emit(event);
    }

    public rowPrepared(event): void {
        this.onRowPrepared.emit(event);
    }

    public rowInserted(event): void {
        this.onRowInserted.emit(event);
    }

    public rowUpdated(event): void {
        this.onRowUpdated.emit(event);
    }

    public rowDbClick(event): void {
        this.onRowDblClick.emit(event);
    }

    public cellPrepared(event): void {
        this.onCellPrepared.emit(event);
    }

    public contextMenuPreparing(event): void {
        this.onContextMenuPreparing.emit(event);
    }

    public updateDataGrid(data): void {
        this.dxDataGrid.instance.option('dataSource', data);
    }

    public rowSelect(event): void {
        this.onSelectionChanged.emit(event);
    }

    public valueChanged(event): void {
        this.onValueChanged.emit(event);
    }

    public toolBarPreparing(event) {
        this.onToolBarPreparing.emit(event);
    }

    public addEmptyRow() {
        this.dxDataGrid.instance.addRow();
    }
    public refreshGrid() {
        this.dxDataGrid.instance.refresh();
    }

}