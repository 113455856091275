import { Component, Input, OnInit, Output, EventEmitter, ViewEncapsulation, ViewChild } from '@angular/core';
import { DxTextBoxComponent } from 'devextreme-angular';

@Component({
    selector: 'ww-window-layout',
    template: `<div fxLayout="column" fxFlexFill>
    <div fxLayout="row" fxLayoutGap="5px">
        <div fxLayout="column" fxFlex="15">
            <p class="elipsis">Ident</p>
        </div>
        <div fxLayout="column" fxFlex="100">
            <dx-text-box #ident [stylingMode]="'outlined'" [width]="'100%'" [(value)]="windowData.ident"></dx-text-box>
        </div>
    </div>
    <div fxLayout="row" fxLayoutGap="5px" fxFlexOffset="10px">
        <div fxLayout="column" fxFlex="15">
            <p class="elipsis">Label</p>
        </div>
        <div fxLayout="column" fxFlex="100">
            <dx-text-box #label [(value)]="windowData.label" [stylingMode]="'outlined'" [width]="'100%'"> </dx-text-box>
        </div>
    </div>
    <div fxLayout="column" fxFlexOffset="30px">
        <div style="align-self: flex-end;" fxLayout="row" fxLayoutGap="20px">
            <div fxLayout="column">
                <dx-button class="windowCreateBackground" [text]="'Create'" (onClick)="save()"></dx-button>
            </div>
            <div fxLayout="column">
                <dx-button [text]="'Cancel'" (onClick)="cancel()"></dx-button>
            </div>
        </div>
    </div>
</div>`,
    encapsulation: ViewEncapsulation.Emulated
})
export class WindowLayoutComponent implements OnInit {
    @ViewChild('ident') dxIdentTextBox : DxTextBoxComponent;
    @ViewChild('label') dxLabelTextBox : DxTextBoxComponent;
    @Output() closePopup: EventEmitter<any> = new EventEmitter();
    @Output() onSaving: EventEmitter<any> = new EventEmitter();
    private _windowData;
    @Input()
    get windowData() { return this._windowData; }
    set windowData(windowData) {
        this._windowData = windowData;
    }

    ngOnInit(): void {
    }

    public cancel(): void {
        this.dxIdentTextBox.instance.reset();
        this.dxLabelTextBox.instance.reset();
        this.closePopup.emit(true);
    }

    public save(): void {
        this.onSaving.emit();
        this.closePopup.emit(true);
    }

}
