import { Injectable } from "@angular/core";
import { CoreConfigService } from "@wissenswerft/core/configuration";
import { Observable } from "rxjs";
import { DataService, TypeKey } from "./data.service";
import { ConvertHelper } from './helpers';

@Injectable({
    providedIn: 'root'
})
export class PersistenceService {
    private pack = [];
    private createItemUri: string;
    private updateItemUri: string;
    private deleteItemUri: string;
    private commentUri : string;
    private deleteCommentUri : string;

    constructor(private dataService: DataService, private configService: CoreConfigService) {
        this.createItemUri = this.configService.configuration.WebApi.ServiceURLs.CreateItem;
        this.updateItemUri = this.configService.configuration.WebApi.ServiceURLs.UpdateOrDeleteItem;
        this.deleteItemUri = this.configService.configuration.WebApi.ServiceURLs.UpdateOrDeleteItem;
        this.deleteCommentUri = this.configService.configuration.WebApi.ServiceURLs.CommentDeleteById;
        this.commentUri = this.configService.configuration.WebApi.ServiceURLs.CommentsByItemId;
    }

    public addObjectValue(object: any, fieldName: string, newValue: any) {
        this.pack.push({
            [fieldName]: newValue
        });
    }

    public addObjectForInsert(name: TypeKey, query): Observable<any> {
        return this.dataService.executeWebApiPostMethod(ConvertHelper.resolveStringPlaceholders(this.createItemUri, this.dataService.scope, name), query);
    }
    public addObjectForUpdate(id: number, query): Observable<any> {
        return this.dataService.executeWebApiPutMethod(ConvertHelper.resolveStringPlaceholders(this.updateItemUri, this.dataService.scope, id), query);
    }

    public addObjectForDelete(id: number): Observable<any> {
        return this.dataService.executeWebApiDeleteMethod(ConvertHelper.resolveStringPlaceholders(this.deleteItemUri, this.dataService.scope, id));
    }

    public addComment(query, id): Observable<any> {
        console.log(query)
        return this.dataService.executeWebApiPostMethod(ConvertHelper.resolveStringPlaceholders(this.commentUri, this.dataService.scope, id), query);
    }

    public updateComment(id ,query): Observable<any> {
        return this.dataService.executeWebApiPutMethod(ConvertHelper.resolveStringPlaceholders(this.commentUri, this.dataService.scope, id), query);
    }

    public deleteComment(postingId, itemId): Observable<any> {
        return this.dataService.executeWebApiDeleteMethod(ConvertHelper.resolveStringPlaceholders(this.deleteCommentUri, this.dataService.scope, itemId, postingId));
    }

}