import { MeasureRecommendation } from "@wissenswerft/cmt/catalog-library";
import { Measure, MeasureTask } from "../models/measure.model";

export class MeasureTaskViewModel {
    private measureTask: MeasureTask;
    get ident(): string { return this.measureTask.ident; }
    get label(): string { return this.measureTask.label; }
    get degreeOfImplementation(): number { return this.measureTask.degreeOfImplementation; }
    get efficiency(): number { return this.measureTask.efficiency; }
    get notes(): string { return this.measureTask.notes; }
    get measure(): MeasureRecommendation { return this.measureTask.measure; }

    set ident(ident) { this.measureTask.ident = ident; }
    set label(label) { this.measureTask.label = label; }
    set degreeOfImplementation(degreeOfImplementation: number) { this.measureTask.degreeOfImplementation = degreeOfImplementation }
    set efficiency(efficiency: number) { this.measureTask.efficiency = efficiency }
    set notes(notes: string) { this.measureTask.notes = notes; }
    set measure(measure: MeasureRecommendation) { this.measureTask.measure = measure; }

    constructor(data: MeasureTask) {
        this.measureTask = data;
    }
}

export const VISIBLE_PROPERTIES = [
    'label', 'degreeOfImplementation', 'efficiency', 'notes', 'measure'
];