import { AccessibleScopes } from '@wissenswerft/core/authentication';
import {
  DataDefinition,
  PropertyDefinition,
} from '../models/customer-space.model';

export class DataDefinitionViewModel {
  protected dataDefinition: DataDefinition;
  private _properties: {};
  private _visibleProperties: Array<string>;

  get name(): string {
    return this.dataDefinition.name;
  }
  get namePlural(): string {
    return this.dataDefinition.namePlural;
  }
  get properties() {
    if (!this._properties) {
      this._properties = {};
      for (
        let index = 0;
        index < this.dataDefinition.properties.length;
        index++
      ) {
        const property = this.dataDefinition.properties[index];
        if (this._visibleProperties.includes(property.key)) {
          property.visible = true;
        } else {
          property.visible = false;
        }
        this._properties[property.key] = property;
      }
    }
    return this._properties;
  }

  get multilingualProperties(): PropertyDefinition[] {
    let multilingualProperties: PropertyDefinition[] = [];
    this.dataDefinition.properties.forEach((element) => {
      if (element.multilingual === true) {
        multilingualProperties.push(element);
      }
    });
    return multilingualProperties;
  }

  get listProperties(): PropertyDefinition[] {
    let listProperties: PropertyDefinition[] = [];
    this.dataDefinition.properties.forEach((element) => {
      if (element.list == true) {
        listProperties.push(element);
      }
    });
    return listProperties;
  }

  constructor(data: DataDefinition, visibleProperties) {
    this.dataDefinition = data;
    this._visibleProperties = visibleProperties;
  }
}

export class AccessibleScopesViewModel {
  private accessibleScopes: AccessibleScopes[] = [];
  private _scopeByName: NamedScope;

  get scopeByName(): NamedScope {
    if (!this._scopeByName) {
        this._scopeByName = {};
      this.accessibleScopes.forEach((scope) => {
          this._scopeByName[scope.scopeKey] = scope;
      });
    }
    return this._scopeByName;
  }

  constructor(data) {
    this.accessibleScopes = data;
  }
}

export interface NamedScope {
    [key: string]: AccessibleScopes
}
