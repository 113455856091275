import { Component, OnInit } from '@angular/core';
import { TypeKey } from '@wissenswerft/core/data';

@Component({
    selector: 'measure-benefit',
    templateUrl: './measure-benefit.component.html',
    styleUrls: ['./measure-benefit.component.scss']
})
export class MeasureBenefitComponent implements OnInit {
    public typeKey: TypeKey = TypeKey.measureBenefit;

    constructor() { }

    ngOnInit(): void {

    }
}