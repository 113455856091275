import { Injectable } from '@angular/core';
import { LoginInfo } from '../models/login-info.model';

const ACCESS_TOKEN = 'access_token';
const REFRESH_TOKEN = 'refresh_token';
const EXPIRES = 'expires';
const EXPIRY_DATE = 'expiry_date';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor() { }

  getToken(): string {
    return localStorage.getItem(ACCESS_TOKEN);
  }

  getRefreshToken(): string {
    return localStorage.getItem(REFRESH_TOKEN);
  }

  getExpiryDate(): string {
    return localStorage.getItem(EXPIRY_DATE);
  }

  saveToken(loginInfo: LoginInfo): void {
    localStorage.setItem(ACCESS_TOKEN, loginInfo.accessToken);
    localStorage.setItem(REFRESH_TOKEN, loginInfo.refreshToken);
    localStorage.setItem(EXPIRES, loginInfo.expiresIn.toString());
    localStorage.setItem(EXPIRY_DATE, loginInfo.expiryDate.toString());
  }

  removeToken(): void {
    localStorage.removeItem(ACCESS_TOKEN);
  }

  removeRefreshToken(): void {
    localStorage.removeItem(REFRESH_TOKEN);
  }

  clearLoginInfo(): void {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    localStorage.removeItem(EXPIRES);
    localStorage.removeItem(EXPIRY_DATE);
  }
}
