import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LegalBasis } from '@wissenswerft/cmt/catalog-library';
import { TypeKey } from '@wissenswerft/core/data';
import { DxPopupComponent, DxSelectBoxComponent, DxTextBoxComponent } from 'devextreme-angular';
import { Column } from 'devextreme/ui/data_grid';
import { PersistenceService } from 'libs/core/data/src/lib/persistence.service';
import { DataService } from '../../../shared/data.service';
import { GridComponent } from '../../shared/ww-grid/grid.component';
import { DataDefinitionViewModel } from '../../view-models/customer-space.view-model';
import { LegalBasisService } from './legal-basis.service';
import { LegalBasisViewModel, VISIBLE_PROPERTIES } from '../../view-models/legal-basis.view-model'
import { Observable, Subscription } from 'rxjs';
import { WindowLayoutComponent } from '../../shared/ww-window-layout/windowLayout.component';
import { ToastType } from '../../shared/ww-toast/toast.model';

@Component({
  selector: 'legal-basis',
  templateUrl: './legal-basis.component.html',
  styleUrls: ['./legal-basis.component.scss']
})
export class LegalBasisComponent implements OnInit, OnDestroy {
  @ViewChild('legalBasisGrid') legalBasisGrid: GridComponent;
  @ViewChild('windowLayout') windowLayout: WindowLayoutComponent;
  @ViewChild('createLegalBasisPopup') createLegalBasisPopup: DxPopupComponent;
  @ViewChild('ident') dxIdentTextBox: DxTextBoxComponent;
  @ViewChild('label') dxLabelTextBox: DxTextBoxComponent;
  @ViewChild('description') dxDescriptionTextBox: DxTextBoxComponent;
  @ViewChild('link') dxLinkTextBox: DxTextBoxComponent;

  public legalBasis: LegalBasis;
  public title: string;
  public legalsBasis: LegalBasisViewModel[];
  private subscriptions: Subscription[] = [];
  public columnsHeader: Column[] = [];
  constructor(private dataService: DataService,
    private persistenceService: PersistenceService,
    public legalBasisService: LegalBasisService) { }


  ngOnInit(): void {
    this.legalBasis = new LegalBasis();
    if (this.dataService.definitionsVM[TypeKey.legalBasis]) {
      this.title = this.dataService.definitionsVM[TypeKey.legalBasis].namePlural;
    }
    this.subscriptions.push(this.dataService.getDefinitionAndData<LegalBasis[]>(TypeKey.legalBasis).subscribe(data => {
      const definitions = data[0];
      this.dataService.definitionsVM[TypeKey.legalBasis] = definitions;
      this.title = definitions.namePlural;
      const legalsBasis = data[1];
      this.legalsBasis = [];
      const legalBasisDefinitionVM = new DataDefinitionViewModel(definitions, VISIBLE_PROPERTIES);
      this.dataService.definitionsVM[TypeKey.legalBasis].definitionVM = legalBasisDefinitionVM;
      const properties = legalBasisDefinitionVM.properties;
      for (const key in properties) {
        if (properties[key]) {
          const property = properties[key];
          this.columnsHeader.push({
            dataField: property.key,
            caption: property.label,
            visible: property?.visible,
            dataType: this.dataService.getDataType(property.type)
          });
        }
      }
      legalsBasis.forEach((legalBasis: LegalBasis) => {
        const legalBasisViewModel = new LegalBasisViewModel(legalBasis);
        this.legalsBasis.push(legalBasisViewModel);
      });
    }));

    this.subscriptions.push(this.dataService.updateGridData$.subscribe((legalBasis: LegalBasisViewModel) => {
      this.legalsBasis.push(legalBasis);
    }));
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => { subscription.unsubscribe(); });
  }

  public removeRow(event): void {
    const legalBasisId = event.data.id || event.data.legalBasis.id;
    this.persistenceService.addObjectForDelete(legalBasisId).subscribe(() => {
      this.dataService.appService.callNotification({ message: 'Objekt gelöscht', type: ToastType.INFO });
    }, error => {
      this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
    });
  }

  public rowUpdated(event): void {
    const legalBasisId = event.data.id || event.data.legalBasis.id;
    const legalBasis: LegalBasisViewModel = new LegalBasisViewModel(event.data);
    const query = this.legalBasisService.prepareLegalBasisiPersistObject(legalBasis);
    const multilingualProperties = this.dataService.definitionsVM[TypeKey.legalBasis].definitionVM.multilingualProperties;
    const listProperties = this.dataService.definitionsVM[TypeKey.legalBasis].definitionVM.listProperties;
    const object = this.dataService.createPersistObject(query, multilingualProperties, listProperties);
    this.persistenceService.addObjectForUpdate(legalBasisId, object).subscribe(() => {
      this.dataService.appService.callNotification({ message: 'Erfolg', type: ToastType.SUCCESS });
    }, error => {
      this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
    });
  }

  public openLegalBasisDialog(): void {
    this.createLegalBasisPopup.instance.show();
  }

  public createLegalBasis(): void {
    this.persistObject().subscribe((data: LegalBasisViewModel) => {
      this.dataService.updateGridData(data);
      this.dataService.appService.callNotification({ message: 'Erfolg', type: ToastType.SUCCESS });
      this.clearWindow();
    }, error => {
      this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
    });
    this.legalBasis = new LegalBasis();
  }

  private persistObject(): Observable<any> {
    const multilingualProperties = this.dataService.definitionsVM[TypeKey.legalBasis].definitionVM.multilingualProperties;
    const listProperties = this.dataService.definitionsVM[TypeKey.legalBasis].definitionVM.listProperties;
    const query = this.dataService.createPersistObject(this.legalBasis, multilingualProperties, listProperties);
    return this.persistenceService.addObjectForInsert(TypeKey.legalBasis, query);
  }

  public clearWindow(): void {
    this.createLegalBasisPopup.instance.hide();
    this.dxIdentTextBox.instance.reset();
    this.dxLabelTextBox.instance.reset();
    this.dxDescriptionTextBox.instance.reset();
    this.dxLinkTextBox.instance.reset();
  }
}


