import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DxPopupComponent } from 'devextreme-angular';
import { PropertyEnum, RiskAssessment } from '../../models/risk-assesment.model';
import { Column } from 'devextreme/ui/data_grid';
import { RiskAssessmentViewModel, VISIBLE_PROPERTIES } from '../../view-models/risk-assessment.view-model';
import { DataDefinitionViewModel } from '../../view-models/customer-space.view-model';
import { Router } from '@angular/router';
import { DataService } from '../../../shared/data.service';
import { RiskAssessmentService } from './risk-assessment.service';
import { GridComponent } from '../../shared/ww-grid/grid.component';
import { forkJoin, Subject } from 'rxjs';
import { concatMap, map, takeUntil } from 'rxjs/operators';
import { AppService } from '../../../app.service';
import { CoreDataService, PersistenceService, TypeKey } from '@wissenswerft/core/data';
import { Country, Module, SubModule } from '@wissenswerft/cmt/catalog-library';
import { ProfileInfo } from '@wissenswerft/core/authentication';
import { ToastType } from '../../shared/ww-toast/toast.model';

@Component({
  selector: 'cmt-risk-assesment',
  templateUrl: './risk-assesment.component.html',
  styleUrls: ['./risk-assesment.component.scss']
})
export class RiskAssesmentComponent implements OnInit, OnDestroy {

  private destroy$ = new Subject<void>();
  public detailsMode: string;

  @ViewChild('riskDetailsPopup') riskDetailsPopup: DxPopupComponent;
  @ViewChild('confirmPopup') confirmPopup: DxPopupComponent;
  @ViewChild('riskGrid') riskGrid: GridComponent;

  public riskAssessments: RiskAssessmentViewModel[];
  public columnsHeader: Column[] = [];
  public title: string;
  private riskId;
  public openCreateDialog: boolean;
  public selectedModule: Module;
  private responsibles: ProfileInfo[];
  private countries: Country[]
  private groupByColumnIndex = [];

  constructor(private app: AppService,
    private router: Router,
    private dataService: DataService,
    private coreDataService: CoreDataService,
    private persistenceService: PersistenceService, public riskService: RiskAssessmentService) {
      // this.coreDataService.getEnumProperty<PropertyEnum[]>(TypeKey.riskAssessment, 'amountOfDamage').subscribe((data) => {
      //   this.riskService.amountOfDamage.forEach((val, i) => {
      //     this.riskService.amountOfDamage[i].label = data[val.value].de;
      //   })
      // })
      // this.coreDataService.getEnumProperty<PropertyEnum[]>(TypeKey.riskAssessment, 'probability').subscribe((data) => {
      //   this.riskService.probability.forEach((val, i) => {
      //     this.riskService.probability[i].label = data[val.value].de;
      //   })
      // })
    this.riskService.responsibles.subscribe(responsibles => {
      this.responsibles = responsibles;
    })
    this.riskService.countries.subscribe(countries => {
      this.countries = countries;
    })
  }

  ngOnInit(): void {
    this.dataService.checkGridGroupByColumns('riskGrid', this.groupByColumnIndex);
    this.riskService.prepareModules();
    if (this.dataService.definitionsVM[TypeKey.riskAssessment]) {
      this.title = this.dataService.definitionsVM[TypeKey.riskAssessment].namePlural;
    }
    this.riskService.getSelectedModule().subscribe((module) => {
      this.selectedModule = module;
    });
    this.dataService.getDefinitionAndData<RiskAssessment[]>(TypeKey.riskAssessment)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        const definitions = data[0];
        this.dataService.definitionsVM[TypeKey.riskAssessment] = definitions;
        this.title = definitions.namePlural;
        const riskAssessments = data[1];
        this.riskAssessments = [];
        const riskAssessmentDefinitionVM = new DataDefinitionViewModel(definitions, VISIBLE_PROPERTIES);
        this.riskService.riskAssessmentDefinitionVM = riskAssessmentDefinitionVM;
        riskAssessments.forEach((risk) => {
          const riskModel = new RiskAssessment(risk);
          riskModel.module = this.riskService.moduleById[riskModel.subModule?.module];
          const riskViewModel = new RiskAssessmentViewModel(riskModel);
          this.riskAssessments.push(riskViewModel);
        });
        const properties = riskAssessmentDefinitionVM.properties;
        const getGroupIndex = (key) => {
          if (key === 'module') {
            return 0;
          }
          if (key === 'subModule') {
            return 1;
          }
          return this.groupByColumnIndex[key];
        }
        for (const key in properties) {
          if (properties[key]) {
            const property = properties[key];
            this.columnsHeader.push({
              dataField: property.key,
              caption: property.label,
              visible: property?.visible,
              dataType: this.dataService.getDataType(property.type),
              groupIndex: getGroupIndex(property.key) ?? undefined,
              lookup: (this.getLookupColumn(property.key)) ? {
                dataSource: this.getLookupColumn(property.key).dataSource,
                displayExpr: this.getLookupColumn(property.key).displayExpr,
                valueExpr: this.getLookupColumn(property.key).valueExpr
              } : undefined,
            });
          }
        }
        this.columnsHeader.push({
          dataField: 'riskAverage',
          caption: '',
          visible: true,
          dataType: 'string',
          width: 50
        });
        this.columnsHeader.push({
          type: "buttons",
          caption: '',
          alignment: 'left',
          minWidth: 70,
          dataField: 'edit',
          buttons: [{
            icon: 'edit',
            onClick: (e) => { this.rowDbClick(e); }
          }, {
            icon: "trash",
            onClick: (e) => { this.openConfirmDialog(e); }
          }]
        });
      });
    this.dataService.updateGridData$.subscribe((risk: RiskAssessment) => {
      this.riskAssessments.push(new RiskAssessmentViewModel(risk));
    });
  };

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private getLookupColumn(key: string) {
    switch (key) {
      case 'amountOfDamage':
      case 'probability':
        return { valueExpr: 'value', displayExpr: 'label', dataSource: this.riskService.probability };
      case 'responsible':
        return { valueExpr: 'id', displayExpr: this.getResponsibleFullName, dataSource: this.responsibles };
      case 'typeOfDamage':
        return { valueExpr: 'value', displayExpr: 'label', dataSource: this.riskService.typeOfDamage };
      case 'status':
        return { valueExpr: 'value', displayExpr: 'label', dataSource: this.riskService.status };
        case 'countries':
        return { valueExpr: 'id', displayExpr: 'label', dataSource: this.countries };
    }
  }
  
  public getResponsibleFullName(responsible: ProfileInfo) {
    if (responsible) {
      return responsible.lastname + ', ' + responsible.firstname;
    }
  }

  public onOpenRiskDialog(): void {
    this.openCreateDialog = true;
    this.detailsMode = 'simple';
    this.riskDetailsPopup.visible = true;
  }

  public onClosePopup(event: Event): void {
    this.riskDetailsPopup.visible = false;
  }

  public onHiding(event): void {
    // event.cancel = true;
  }

  public rowDbClick(event): void {
    const riskDetail = new RiskAssessment(event.row.data.riskAssessment);
    this.dataService.sendRiskDetail({
      riskAssessment: riskDetail,
      riskDefinition: this.riskService.riskAssessmentDefinitionVM
    });
    this.router.navigate(['riskAssessmentDetail', riskDetail.id]);
  }

  public abortDelete() {
    this.confirmPopup.instance.hide();
  }

  public removeRow(event): void {
    this.persistenceService.addObjectForDelete(this.riskId)
      .subscribe(() => {
        let index = this.riskGrid.gridData.findIndex(element => element.riskAssessment.id == this.riskId);
        this.riskGrid.gridData.splice(index, 1);
        this.dataService.appService.callNotification({ message: 'Objekt gelöscht', type: ToastType.INFO });
      }, error => {
        this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
      });
    this.confirmPopup.instance.hide();
  }

  public openConfirmDialog(event): void {
    this.riskId = event.row.data.riskAssessment.id;
    this.confirmPopup.instance.show();
  }
  public onSelectModule(event) {
    this.riskService.setSelectedModule(event.itemData);
    this.selectedModule = event.itemData;
    this.coreDataService.getItemsBySpoqlQuery<SubModule[]>('subModule', `property 'module' eq ${event.itemData.id}`).pipe(concatMap(subModules => {
      let queries = [];
      this.riskAssessments = [];
      subModules.forEach(subModule => {
        queries.push(this.coreDataService.getItemsBySpoqlQuery<RiskAssessment[]>('riskIssue', `property 'subModule' eq ${subModule.id}`).pipe(map(riskIssues => {
          riskIssues.forEach(riskIssue => {
            this.riskAssessments.push(new RiskAssessmentViewModel(riskIssue));
          });
        })));
      });
      return forkJoin(queries);

    })).subscribe();
  }

  public onGridCellPrepared(event) {
    if (event.column?.dataField === 'riskAverage') {
      const value: number = event?.value || 0;
      event.cellElement.innerHTML = '';
      if ([1,3,5,7].includes(value)) {
        return event.cellElement.bgColor = 'lime'
      } else if ([9,15,21].includes(value)) {
        return event.cellElement.bgColor = 'yellow';
      } else if ([25,35].includes(value)) {
        return event.cellElement.bgColor = 'orange';
      } else if (value === 49) {
        return event.cellElement.bgColor = 'red';
      } else {
        return;
      }
    }
  }

}
