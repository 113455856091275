export class RiskDistribution {
    probability: string;
    damage: string;
    sum: number;
    constructor(probability, damage, sum) {
        this.probability = probability;
        this.damage = damage;
        this.sum = sum;
    }
}

export enum MatrixViewKey {
    Niedrig = 1,
    Mittel = 3,
    Hoch = 5,
    SehrHoch = 7
}

export enum Damage {
    LOW = 'Niedrig',
    MEDIUM = 'Mittel',
    HIGH = 'Hoch',
    VERYHIGH = 'Sehr Hoch'
}
export class DashBoardMeasure {
    id: number;
    title: string;
    deadLine: Date;
    ram: number[];
    riskReduction: number;
    constructor(id, title, deadLine, ram, riskReduction) {
        this.id = id;
        this.title = title;
        this.deadLine = deadLine
        this.ram = ram;
        this.riskReduction = riskReduction;
    }
}

export class DashBoardRisk {
    id: number | string;
    module: string;
    risk: string;
    damage: number[];
    constructor(id, module,damage, risk) {
        this.id = id;
        this.module = module;
        this.damage = damage;
        this.risk = risk;
    }
}
