import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Standard } from '@wissenswerft/cmt/catalog-library';
import { PersistenceService, TypeKey } from '@wissenswerft/core/data';
import { Observable, Subscription } from 'rxjs';
import { DataService } from '../../../shared/data.service';
import { DataDefinitionViewModel } from '../../view-models/customer-space.view-model';
import { StandardService } from './standard.service';
import { StandardViewModel, VISIBLE_PROPERTIES } from '../../view-models/standard.view-model'
import { Column } from 'devextreme/ui/data_grid';
import { GridComponent } from '../../shared/ww-grid/grid.component';
import { DxPopupComponent, DxTextBoxComponent } from 'devextreme-angular';
import { ToastType } from '../../shared/ww-toast/toast.model';


@Component({
    selector: 'standard',
    templateUrl: './standard.component.html',
    styleUrls: ['./standard.component.scss']
})
export class StandardComponent implements OnInit, OnDestroy {
    @ViewChild('standardGrid') standardGrid: GridComponent;
    @ViewChild('createStandardPopup') createStandardPopup: DxPopupComponent;
    @ViewChild('ident') dxIdentTextBox: DxTextBoxComponent;
    @ViewChild('label') dxLabelTextBox: DxTextBoxComponent;
    @ViewChild('description') dxDescriptionTextBox: DxTextBoxComponent;

    private subscriptions: Subscription[] = [];
    public title: string;
    public standard: Standard;
    public standards: StandardViewModel[];
    public columnsHeader: Column[] = [];

    constructor(private dataService: DataService,
        private persistenceService: PersistenceService,
        private standardService: StandardService) { }

    ngOnInit(): void {
        this.standard = new Standard();
        if (this.dataService.definitionsVM[TypeKey.standard]) {
            this.title = this.dataService.definitionsVM[TypeKey.standard].namePlural;
        }
        this.subscriptions.push(this.dataService.getDefinitionAndData<Standard[]>(TypeKey.standard).subscribe(data => {
            const definitions = data[0];
            this.dataService.definitionsVM[TypeKey.standard] = definitions;
            this.title = definitions.namePlural;
            const standards = data[1];
            this.standards = [];
            const standardDefinitionVM = new DataDefinitionViewModel(definitions, VISIBLE_PROPERTIES);
            this.dataService.definitionsVM[TypeKey.standard].standardDefinitionVM = standardDefinitionVM;
            const properties = standardDefinitionVM.properties;
            for (const key in properties) {
                if (properties[key]) {
                    const property = properties[key];
                    this.columnsHeader.push({
                        dataField: property.key,
                        caption: property.label,
                        visible: property?.visible,
                        dataType: this.dataService.getDataType(property.type)
                    });
                }
            }
            standards.forEach((standard: Standard) => {
                const standardViewModel = new StandardViewModel(standard);
                this.standards.push(standardViewModel);
            });
        }));

        this.subscriptions.push(this.dataService.updateGridData$.subscribe((standard: StandardViewModel) => {
            this.standards.push(standard);
        }));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => { subscription.unsubscribe(); });
    }

    public openStandardDialog(): void {
        this.createStandardPopup.instance.show();
    }

    public cancel(): void {
        this.createStandardPopup.instance.hide();
        this.dxIdentTextBox.instance.reset();
        this.dxLabelTextBox.instance.reset();
        this.dxDescriptionTextBox.instance.reset();
    }

    public removeRow(event): void {
        const standardId = event.data.id || event.data.standard.id;
        this.persistenceService.addObjectForDelete(standardId).subscribe(() => {
            this.dataService.appService.callNotification({ message: 'Objekt gelöscht', type: ToastType.INFO });
        }, error => {
            this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
        });
    }

    public rowUpdated(event): void {
        const standardId = event.data.id || event.data.standard.id;
        const standard: StandardViewModel = new StandardViewModel(event.data);
        const query = this.standardService.prepareStandardPersistObject(standard);
        const multilingualProperties = this.dataService.definitionsVM[TypeKey.standard].standardDefinitionVM.multilingualProperties;
        const listProperties = this.dataService.definitionsVM[TypeKey.standard].standardDefinitionVM.listProperties;
        const object = this.dataService.createPersistObject(query, multilingualProperties, listProperties);
        this.persistenceService.addObjectForUpdate(standardId, object).subscribe(() => {
            this.dataService.appService.callNotification({ message: 'Erfolg', type: ToastType.SUCCESS });
        }, error => {
            this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
        });
    }

    public save(): void {
        this.persistObject().subscribe((data: StandardViewModel) => {
            this.dataService.appService.callNotification({ message: 'Erfolg', type: ToastType.SUCCESS });
            this.dataService.updateGridData(data);
            this.createStandardPopup.instance.hide();
        }, error => {
            this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
        });
        this.standard = new Standard();
    }

    private persistObject(): Observable<any> {
        const multilingualProperties = this.dataService.definitionsVM[TypeKey.standard].standardDefinitionVM.multilingualProperties;
        const listProperties = this.dataService.definitionsVM[TypeKey.standard].standardDefinitionVM.listProperties;
        const query = this.dataService.createPersistObject(this.standard, multilingualProperties, listProperties);
        return this.persistenceService.addObjectForInsert(TypeKey.standard, query);
    }
}