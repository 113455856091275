import { Injectable } from '@angular/core';
import { ResponsiblePlan } from '@wissenswerft/cmt/catalog-library';
import { ProfileInfo } from '@wissenswerft/core/authentication';
import { CoreDataService, TypeKey } from '@wissenswerft/core/data';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Measure } from '../../models/measure.model';
@Injectable({
  providedIn: 'root'
})
export class ResponsiblePlanService {
  private _responsibles: Observable<ProfileInfo[]>;
  public responsibleById: {}
  private _measures: Observable<Measure[]>;
  public measureById: {}
  constructor(private coreDataService : CoreDataService) {
  }

  public prepareResponsiblePersistObject(responsiblePlan: ResponsiblePlan): ResponsiblePlan {
    const query: ResponsiblePlan = new ResponsiblePlan(null);
    query.plannedDays = responsiblePlan.plannedDays;
    query.remainingDays = responsiblePlan.remainingDays;
    query.responsible = responsiblePlan.responsible;
    query.measure = responsiblePlan.measure;
    return query;
  }

  public get responsibles(): Observable<ProfileInfo[]> {
    if (!this._responsibles) {
      this._responsibles = this.coreDataService.getAllAccounts<ProfileInfo[]>().pipe(map(users => {
        this.responsibleById = {};
        users.forEach((user => {
          user = new ProfileInfo(user);
          this.responsibleById[user.id] = user;
        }));
        return users;
      }));
    }
    return this._responsibles;
  }

  public get measures(): Observable<Measure[]> {
    if (!this._measures) {
      this._measures = this.coreDataService.getItemsByName<Measure[]>(TypeKey.measure).pipe(map(measures => {
        this.measureById = {};
        measures.forEach((measure => {
          measure = new Measure(measure);
          this.measureById[measure.id] = measure;
        }));
        return measures;
      }));
    }
    return this._measures;
  }

}
