import { Component, OnInit } from '@angular/core';
import { TypeKey } from '@wissenswerft/core/data';


@Component({
  selector: 'country',
  templateUrl: './country.component.html',
  styleUrls: ['./country.component.scss']
})
export class CountryComponent implements OnInit {
  public typeKey: TypeKey = TypeKey.country;

  constructor() { }

  ngOnInit(): void {

  }

}
