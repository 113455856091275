import { EventEmitter, Input, Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ConvertHelper, CoreDataService, PersistenceService, TypeKey } from '@wissenswerft/core/data';
import { Observable } from 'rxjs';
import { MeasureBenefit } from '@wissenswerft/cmt/catalog-library';
import { Measure, MeasureDefinition, OrgaTech, PrevDect, Status } from '../../../models/measure.model';
import { MeasureViewModel } from '../../../view-models/measure.view-model';
import { DataService } from 'apps/cmt/src/app/shared/data.service';
import { ProfileInfo } from '@wissenswerft/core/authentication';
import { ToastType } from '../../../shared/ww-toast/toast.model';
import { PropertyDefinition } from '../../../models/customer-space.model';

@Component({
  selector: 'measure-create',
  templateUrl: './measure-create.component.html',
  styleUrls: ['./measure-create.component.scss']
})
export class MeasureCreateComponent implements OnInit {
  @Input() propertiesDefinitions;
  @Output() closePopup: EventEmitter<any> = new EventEmitter();
  public measureBenefits: Observable<MeasureBenefit[]>;
  public measureDefinitions: Observable<MeasureDefinition[]>;
  public measure: Measure;
  public orgaTech = ConvertHelper.ToArray(OrgaTech);
  public prevDect = ConvertHelper.ToArray(PrevDect);
  public responsibles: Observable<ProfileInfo[]>;

  constructor(private coreDataService: CoreDataService, public dataService: DataService, private persistenceService: PersistenceService) { }

  ngOnInit(): void {
    this.measure = new Measure(null);
    this.measureBenefits = this.coreDataService.getItemsByName(TypeKey.measureBenefit);
    this.measureDefinitions = this.coreDataService.getItemsByName(TypeKey.measureDefinition);
    this.responsibles = this.coreDataService.getAllAccounts();
  }

  public createMeasure(): void {
    this.measure.status = Status.open;
    this.persistObject().subscribe((data: MeasureViewModel) => {
      this.dataService.updateGridData(data);
      this.dataService.appService.callNotification({ message: 'Erfolg', type: ToastType.SUCCESS });
      this.closePopup.emit(true);
    }, error => {
      this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
    }); 
    this.measure = new Measure(null);
  }

  public persistObject(): Observable<any> {
    const multilingualProperties = this.dataService.definitionsVM[TypeKey.measure].measureDefinitionVM.multilingualProperties;
    const listProperties = this.dataService.definitionsVM[TypeKey.measure].measureDefinitionVM.listProperties;
    const query = this.dataService.createPersistObject(this.measure, multilingualProperties, listProperties);
    return this.persistenceService.addObjectForInsert(TypeKey.measure, query);
  }
  
  public cancel(): void {
    this.closePopup.emit(true);
    this.measure = new Measure(null);
  }

  public getResponsibleFullName(responsible: ProfileInfo) {
    if (responsible) {
      return responsible.lastname + ', ' + responsible.firstname;
    }
  }
  public setResponsible(e) {
    return e ? e.id : null;
  }

}
