import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
// Modules
import { AppRoutingModule } from './app-routing.module';
import { CommonModule } from '@angular/common';
import {
  DxCheckBoxModule,
  DxButtonModule,
  DxCircularGaugeModule,
  DxDataGridModule,
  DxDrawerModule,
  DxFormModule,
  DxPopupModule,
  DxScrollViewModule,
  DxSelectBoxModule,
  DxSpeedDialActionModule,
  DxTextAreaModule,
  DxTextBoxModule,
  DxToolbarModule,
  DxTreeViewModule,
  DxAccordionModule,
  DxTileViewModule,
  DxDateBoxModule,
  DxListModule,
  DxNumberBoxModule,
  DxSliderModule,
  DxTagBoxModule,
  DxHtmlEditorModule,
  DxContextMenuModule,
  DxDropDownButtonModule,
  DxTooltipModule,
  DxLinearGaugeModule,
  DxToastModule,
  DxDropDownBoxModule,
  DxPivotGridModule
} from 'devextreme-angular';

// Components
import { AppComponent } from './app.component';
import { DashboardComponent } from './core/dashboard/dashboard.component';
import { GridComponent } from './core/shared/ww-grid/grid.component';
import { ToastComponent } from './core/shared/ww-toast/toast.component';
import { MatrixComponent } from './core/shared/ww-matrix-grid/matrix-grid.component';

import { WindowLayoutComponent } from './core/shared/ww-window-layout/windowLayout.component';
import { HomeComponent } from './core/home/home.component';
////Measures
import { MeasureDetailComponent } from './core/customer-space/measure/measure-detail/measure-detail.component';
import { MeasureListComponent } from './core/customer-space/measure/measure-list.component';
import { MeasureCreateComponent } from './core/customer-space/measure/measure-create/measure-create.component';
////Risks
import { RiskAssesmentDetailComponent } from './core/customer-space/risk-assesment/risk-assesment-detail/risk-assesment-detail.component';
import { RiskAssesmentComponent } from './core/customer-space/risk-assesment/risk-assesment.component';
import { RiskAssessmentCreateComponent } from './core/customer-space/risk-assesment/risk-assessment-create/risk-assessment-create.component';

// Services
import { ScreenService } from './shared/screen.service';
import { DataService } from './shared/data.service'
// Libraries
import { CoreAuthenticationModule } from '@wissenswerft/core/authentication';
import { CoreConfigService, CoreConfigurationModule } from '@wissenswerft/core/configuration';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CoreCommentsModule } from '@wissenswerft/core/comments';

import { ScopeComponent } from './core/customer-space/scope/scope.component';
import { ModulesComponent } from './core/customer-space/modules/modules.component';
import { TagComponent } from './core/customer-space/tag/tag.component';
import { CountryComponent } from './core/customer-space/country/country.component';
import { StandardComponent } from './core/customer-space/standard/standard.component';
import { ProductComponent } from './core/customer-space/product/product.component';
import { MeasureDefinitionComponent } from './core/customer-space/measure-definition/measure-definition.component';
import { MeasureBenefitComponent } from './core/customer-space/measure-benefit/measure-benefit.component';
import { NamedItemComponent } from './core/customer-space/namedItem/named-item.component';
import { DamageKindComponent } from './core/customer-space/damage-kind/damage-kind.component';
import { MeasureTypeComponent } from './core/customer-space/measure-type/measure-type.component';
import { LegalBasisComponent } from './core/customer-space/legal-basis/legal-basis.component';
import { ResponsiblePlanComponent } from './core/customer-space/responsible-plan/responsible-Plan.component';
import { RiskAssesmentMeasureComponent } from './core/customer-space/Risk-Assesment-Measure/risk-Assesment-Measure.component';
import { MeasureTaskComponent } from './core/customer-space/measure-task/measure-task.component';
import { environment } from '../environments/environment';



@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    ToastComponent,
    MatrixComponent,
    GridComponent,
    WindowLayoutComponent,
    HomeComponent,
    MeasureDetailComponent,
    MeasureListComponent,
    RiskAssesmentComponent,
    RiskAssesmentDetailComponent,
    RiskAssessmentCreateComponent,
    MeasureCreateComponent,
    ScopeComponent,
    ModulesComponent,
    TagComponent,
    LegalBasisComponent,
    CountryComponent,
    StandardComponent,
    ProductComponent,
    MeasureDefinitionComponent,
    MeasureBenefitComponent,
    NamedItemComponent,
    DamageKindComponent,
    MeasureTypeComponent,
    ResponsiblePlanComponent,
    RiskAssesmentMeasureComponent,
    MeasureTaskComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    CommonModule,
    CoreAuthenticationModule,
    CoreConfigurationModule,
    CoreCommentsModule,
    DxCheckBoxModule,
    DxButtonModule,
    DxDataGridModule,
    DxDrawerModule,
    DxFormModule,
    DxPopupModule,
    DxScrollViewModule,
    DxSelectBoxModule,
    DxSpeedDialActionModule,
    DxTextAreaModule,
    DxTextBoxModule,
    DxToolbarModule,
    DxTreeViewModule,
    DxCircularGaugeModule,
    DxNumberBoxModule,
    DxSliderModule,
    DxDateBoxModule,
    DxTileViewModule,
    DxTagBoxModule,
    DxListModule,
    DxAccordionModule,
    FlexLayoutModule,
    DxHtmlEditorModule,
    DxContextMenuModule,
    DxDropDownButtonModule,
    DxTooltipModule,
    DxLinearGaugeModule,
    DxToastModule,
    DxDropDownBoxModule,
    DxPivotGridModule
  ],
  providers: [
    CoreConfigService,
    ScreenService,
    DataService,
    {
      provide: APP_INITIALIZER,
      useFactory: ConfigurationFactory,
      deps: [CoreConfigService],
      multi: true
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

export function ConfigurationFactory(globalConfig: CoreConfigService) {
  return () => {
    return new Promise((resolve) => {
      globalConfig.loadConfiguration(environment.API_URL).then((config) => {
        resolve(config);
      }
      );
    });
  };
}
