import { Component, OnInit } from '@angular/core';
import { TypeKey } from '@wissenswerft/core/data';


@Component({
  selector: 'product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {
  public typeKey: TypeKey = TypeKey.product;

  constructor() { }

  ngOnInit(): void {

  }
}