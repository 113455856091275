import { Standard } from "../../../../../../libs/cmt/catalog-library/src/lib/models/catalog-library.model";

export class StandardViewModel {
    private standard: Standard;

    get ident() { return this.standard.ident; }
    get label() { return this.standard.label; }
    get description() { return this.standard.description; }

    set ident(data) { this.standard.ident = data; }
    set label(data) { this.standard.label = data; }
    set description(data) { this.standard.description = data; }

    constructor(data: Standard) {
        this.standard = data;
    }
}

export const VISIBLE_PROPERTIES = [
    'ident', 'label', 'description'
];