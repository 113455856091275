import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { RiskAssessment } from '../../../models/risk-assesment.model';
import { Module, SubModule } from '@wissenswerft/cmt/catalog-library';
import { ProfileInfo } from '@wissenswerft/core/authentication';
import { CoreDataService, PersistenceService, TypeKey } from '@wissenswerft/core/data';
import { DataService } from '../../../../shared/data.service';
import { RiskAssessmentViewModel } from '../../../view-models/risk-assessment.view-model';
import { Router } from '@angular/router';
import { RiskAssessmentService } from '../risk-assessment.service';
import { ToastType } from '../../../shared/ww-toast/toast.model';

@Component({
  selector: 'cmt-risk-assessment-create',
  templateUrl: './risk-assessment-create.component.html',
  styleUrls: ['./risk-assessment-create.component.scss']
})
export class RiskAssessmentCreateComponent implements OnInit {
  public risk: RiskAssessment;
  public subModules: Observable<SubModule[]>;
  public selectedModule: Module;
  // public responsibles: Observable<ProfileInfo[]>;
  // public modules: Observable<Module[]>;

  @Output() closePopup: EventEmitter<any> = new EventEmitter();

  constructor(private coreDataService: CoreDataService, private persistenceService: PersistenceService,
    private dataService: DataService, private router: Router, public riskService: RiskAssessmentService) {

    this.riskService.getSelectedModule().subscribe((module => {
      this.selectedModule = module;
      // this.onSelectModule({
      //   itemData: module
      // });
    }));

  }

  ngOnInit(): void {
    this.risk = new RiskAssessment(null);
    this.riskService.prepareModules();
    // this.responsibles = this.coreDataService.getAllAccounts();
    // this.modules = this.coreDataService.getItemsByName<Module[]>(TypeKey.module);
  }

  public onCancelClick(event: Event): void {
    // this.risk = new RiskAssessment({});
    this.closePopup.emit(true);
    this.clearForm();
  }

  public onRiskSaveClick(event: Event): void {
    this.risk.ident = this.risk.title;
    this.persistObject().subscribe((data: RiskAssessmentViewModel) => {
      this.dataService.updateGridData(data);
      this.dataService.appService.callNotification({ message: 'Erfolg', type: ToastType.SUCCESS });
      this.closePopup.emit(true);
    }, error => {
      this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
    });
    this.clearForm();
  }

  public onCreateDisplayDetailClick(event) {
    this.persistObject().subscribe((riskDetail) => {
      this.dataService.sendRiskDetail({
        riskAssessment: riskDetail,
        riskDefinition: this.riskService.riskAssessmentDefinitionVM
      });
      this.dataService.appService.callNotification({ message: 'Erfolg', type: ToastType.SUCCESS });
      this.router.navigate(['riskAssessmentDetail', riskDetail.id]);
    }, error => {
      this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
    });
    this.closePopup.emit(true);
  }

  public getRsponsibleFullName(responsible) {
    return responsible ? responsible?.lastname + ', ' + responsible?.firstname : '';
  }

  public onSelectModule(event) {
    this.subModules = this.coreDataService.getItemsBySpoqlQuery('subModule', `property 'module' eq ${event.itemData.id}`);
  }

  public persistObject(): Observable<any> {
    const multilingualProperties = this.riskService.riskAssessmentDefinitionVM.multilingualProperties;
    const listProperties = this.riskService.riskAssessmentDefinitionVM.listProperties;
    const query = this.dataService.createPersistObject(this.risk, multilingualProperties, listProperties);
    return this.persistenceService.addObjectForInsert(TypeKey.riskAssessment, query);
  }

  private clearForm(): void {
    this.risk = new RiskAssessment(null);
    // this.riskService.modules = null;
    this.riskService.responsibles = null;
  }

}
