import { Input, OnInit, ViewChild } from '@angular/core';
import { Component } from '@angular/core';
import { DxToastComponent } from 'devextreme-angular';
import { IToast, ToastType } from './toast.model';

@Component({
    selector: 'ww-toast',
    template: `<dx-toast #toast
                [(visible)]="isVisible" 
                [type]="type" 
                [message]="message" 
                [displayTime]="displayTime" 
                [position]="position" 
                [width]="'auto'" >
               </dx-toast>`
})
export class ToastComponent implements OnInit {
    @ViewChild('toast') dxToast : DxToastComponent;
    @Input() type: ToastType = ToastType.INFO;
    @Input() isVisible: boolean = false;
    @Input() position: Object = { at: 'top center', my: 'top center', offset: '0 60' };
    @Input() displayTime: number = 3000;
    private _message;
    @Input()
    get message() { return this._message; }
    set message(message) {
        this._message = message;
    }
    constructor() { }

    ngOnInit(): void {
    }

    public showNotification(notification: IToast): void {
        this.dxToast.message = notification.message;
        this.dxToast.type = notification.type;
        this.dxToast.displayTime = (notification.type === ToastType.ERROR) ? 8000 : 3000;
        this.dxToast.visible = true;        
    }

}



