import { Module, RiskIssue, SubModule } from "@wissenswerft/cmt/catalog-library";
import { ProfileInfo } from '@wissenswerft/core/authentication';
import { Meta } from "./customer-space.model";

export class RiskAssessment extends RiskIssue {
    relevant: boolean;
    existenceThreatening: boolean;
    needsClarification: boolean;
    amountOfDamage: number;
    probability: number;
    status: Status;
    typeOfDamage: TypeOfDamage;
    subModule: SubModule;
    responsible: ProfileInfo;
    meta: Meta;
    module: Module;
    constructor(data) {
        super(data);
        Object.assign(this, data);
        this.subModule = new SubModule(data?.subModule);
        if (data?.responsible) {
            this.responsible = new ProfileInfo(data.responsible);
        }
    }
}

// export const AmountOfDamage = ['low', 'medium', 'high', 'veryhigh'];

export enum AmountOfDamage {
    low = 'Low',
    medium = 'Medium',
    high = 'High',
    veryhigh = 'Very High'
}


export enum Status {
    open = 'open',
    bewertet = 'assessed',
    planned = 'measurePlanned',
    measuresDefined = 'measuresDefined'

}

export enum TypeOfDamage {
    MATERIAL = 'MATERIAL',
    IMMATERIAL = 'IMMATERIAL'
}

export class PropertyEnum {
    sortkey: number;
    de: string;
    en: string
}
